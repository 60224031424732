import React, { useEffect, useState } from "react"
import getDecisionTreeData from "@helpers/dataFunctions/getDecisionTreeData"
import Loader from "@atoms/loader"
import { DecisionTreeNode } from "@molecules"

const DecisionTree = ({ url }: any) => {
  const [data, setData] = useState<any>()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const fetchDecisionTreeData = async () => {
      setIsLoading(true)
      try {
        const res = await getDecisionTreeData(url)
        setData(res)
      } catch (error) {
        console.log(error)
      } finally {
        setIsLoading(false)
      }
    }
    fetchDecisionTreeData()
  }, [])

  if (isLoading) {
    return <Loader display={isLoading} />
  }

  return data ? <DecisionTreeNode {...data} depth={0} /> : <></>
}

export default DecisionTree
