/* eslint-disable react/prop-types */
import { Container, Icon, RichText, Row, WrapperContainer } from "@atoms"
import { ITabItemProps } from "@atoms/tab-item/_tab-item.interface"
import NUMBERS from "@helpers/constants/numbers"
import {
  ButtonGroup,
  CTAButtonGroup,
  CourseInformation,
  CourseOverviewTeaser,
  CustomBanner,
} from "@molecules"
import EmbedMap from "@molecules/embedMap"
import FlexiTabs from "@molecules/flexitabs"
import {
  AccordionWithImage,
  ComparisonTable,
  DosageCalculator,
  LinkWithIcon,
  MediaLibraryDetails,
} from "@organisms"
import {
  EArticleTeaserContainerDefault,
  IArticleTeaserProps,
} from "@organisms/articleTeaser/_article-teaser.interface"
import { IContactCardProps } from "@organisms/contactCard/_contactCard.interface"
import GenericWebForm from "@organisms/genericWebForm"
import { IIconcardData } from "@organisms/iconCard/_iconCard.interface"
import { IMaterialTeaserCardProps } from "@organisms/materialTeaserCard/_materialTeaserCard.interface"
import NewsFilterSearch from "@organisms/newsFilterSearch"
import { INewsStoriesCardProps } from "@organisms/newsStoriesCard/_newsStoriesCard.interface"
import { IOpinionLeadersCardProps } from "@organisms/opinionLeadersCard/_opinionLeadersCard.interface"
import PatientProfile from "@organisms/patientProfile"
import PostcodeSearchFunction from "@organisms/postcodeSearchFunction"
import { IProductCardProps } from "@organisms/productCard/_productCard.interface"
import ProductCategoryBanner from "@organisms/productCategoryBanner"
import ProductList from "@organisms/productlist"
import ProductListV2 from "@organisms/productlistV2"
import { IVideoTeaserCardProps } from "@organisms/videoTeaserCard/_videoTeaserCard.interface"
import ApplicationContext from "@utils/application-context/applicationContext"
import dynamic from "next/dynamic"
import { Key, useContext } from "react"
import { BlockProps } from "./_flexibleBlocks.interface"
import ICON_CARD_CONSTANTS from "@helpers/constants/iconCard"
import useSWRRequest from "@helpers/hooks/useSWRRequest"
import getBlockData from "@helpers/flexibleApi/_flexibleApi.functions"
import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"
import { Dispatch } from "redux"
import { useDispatch } from "react-redux"
import { updatePaywall3Roles } from "store/actions/CommonActionCreators"
import { getFieldPaywallRoles } from "@utils/paywallUtilities"
import PurchaseOrderSearchFilter from "@organisms/purchaseOrderSearchFilter"
import CompanySearchFilter from "@organisms/companySearchFilter"
import UserAccountFilter from "@organisms/userAccount"
import CourseTabs from "@organisms/courseTabs"
import OpenOrderSearchFilter from "@organisms/openOrderSearchFilter"
import PurchaseOrderDetailFilter from "@organisms/purchaseOrderDetailFilter"
import ActivityDetails from "@organisms/activityDetails"

const HeroBanner = dynamic(() => import("../../organisms/heroBanner"))
const RotatingHeroBanner = dynamic(() => import("../../organisms/rotatingHeroBanner"))
const BrandingPattern = dynamic(() => import("@atoms/brandingpattern"))
const ArticleTeaserBanner = dynamic(() => import("../../organisms/articleBanner"))
const ContactCard = dynamic(() => import("../../organisms/contactCard"))
const ProductCard = dynamic(() => import("../../organisms/productCard"), { ssr: false })
const ArticleTeaserComposition = dynamic(() => import("../../organisms/articleTeaser"), {
  ssr: false,
})
const ArticleTeaserFluid = dynamic(() => import("../../organisms/articleTeaserFluid"))
const IconCard = dynamic(() => import("../../organisms/iconCard"), { ssr: false })
const TextBlock = dynamic(() => import("../../molecules/textBlock"))
const Breadcrumbs = dynamic(() => import("../../organisms/breadcrumbs"), { ssr: false })
const VideoTeaserCard = dynamic(() => import("../../organisms/videoTeaserCard"), { ssr: false })
const MaterialTeaserCard = dynamic(() => import("../../organisms/materialTeaserCard"), {
  ssr: false,
})
const OpinionLeadersCard = dynamic(() => import("../../organisms/opinionLeadersCard"), {
  ssr: false,
})
const GoogleMap = dynamic(() => import("../../organisms/googleMaps"))
const Button = dynamic(() => import("@atoms/button"))
const AnchorButton = dynamic(() => import("@atoms/anchorButton"), { ssr: false })
const AddToCalendar = dynamic(() => import("../../organisms/addToCalendar"))
const ArticleTeaserGroup = dynamic(() => import("../../organisms/articleTeaserGroup"))
const ServiceTeaserCard = dynamic(() => import("../../organisms/serviceTeaserCard"), {
  ssr: false,
})
const InterestingTeaserCard = dynamic(() => import("../../organisms/interestingTeaserCard"), {
  ssr: false,
})
const Tabs = dynamic(() => import("../../molecules/tabs"))
const Accordion = dynamic(() => import("../../molecules/accordion"))
const AccordionAdvanced = dynamic(() => import("../../organisms/accordionAdvanced"))
const CarouselWrapper = dynamic(() => import("../../organisms/carouselWrapper"), { ssr: false })
const NewsStoriesCard = dynamic(() => import("../../organisms/newsStoriesCard"), { ssr: false })
const BookmarkWithButton = dynamic(() => import("../../molecules/bookmarkWithButton"), {
  ssr: false,
})
const DetailBlock = dynamic(() => import("../../organisms/DetailBlock"))
const Snackbite = dynamic(() => import("../../organisms/snackbite"))
const AdvancedFilterBlock = dynamic(() => import("../../organisms/advancedFilterBlock"))
const Testimonial = dynamic(() => import("../../organisms/testimonial"))
const RightPanel = dynamic(() => import("../../organisms/right-panel"))
const QuickLinks = dynamic(() => import("../../organisms/quickLinks"))
const QuickLinkCard = dynamic(() => import("../../molecules/quickLinkCard"))
const SocialShare = dynamic(() => import("../../organisms/socialShare"))
const RelatedItems = dynamic(() => import("../../organisms/relatedItems"))
const MediaLibrarySearch = dynamic(() => import("../../organisms/mediaLibrarySearch"))
const Locations = dynamic(() => import("../../organisms/locations"))
const StockTicker = dynamic(() => import("../../molecules/stockTicker"))
const ImageBlock = dynamic(() => import("../../organisms/imageBlock"))
const ContactUs = dynamic(() => import("../../organisms/contactUs"))
const TeaserBlock = dynamic(() => import("../../organisms/teaserBlock"))
const ProductDetailBlock = dynamic(() => import("../../organisms/productDetails"))
const ProductCatalogueBlock = dynamic(() => import("../../organisms/productCatalogue"))
const InlineQuotations = dynamic(() => import("../../organisms/inlineQuotations"))
const CareerSearchFilter = dynamic(() => import("../../organisms/careerSearchFilter"))
const CompactStockTicker = dynamic(() => import("../../organisms/compactStockTicker"))
const CommonSearch = dynamic(() => import("../../organisms/commonSearch"))
const TeaserFeatureCard = dynamic(() => import("../../organisms/teaserFeatureCard"))
const EventCalendar = dynamic(() => import("../../organisms/eventDatepicker"))
const RelatedContent = dynamic(() => import("../../organisms/relatedContent"))
const MediaPlayer = dynamic(() => import("../../molecules/mediaPlayer"))
const WebshopSearchFilter = dynamic(() => import("../../organisms/webstoreSearchFilter"))
const NewsLetterSignUp = dynamic(() => import("../../organisms/newsLetterSignUp"))
const WebStoreDetail = dynamic(() => import("../../organisms/webStoreDetails"))
const ProductFamilyList = dynamic(() => import("../../organisms/familyProductList"))
const SolarTableEnhanced = dynamic(() => import("../../organisms/solarTableEnhanced"))
const BuyNowButton = dynamic(() => import("../../molecules/buyNowButton"))
const DynamicChart = dynamic(() => import("../../organisms/dynamicChart"))
const SimpleVideo = dynamic(() => import("../../atoms/simpleVideo"))
const VideoGallery = dynamic(() => import("../../organisms/videoGallery"))
const UserQuiz = dynamic(() => import("../../organisms/userQuiz"))
const IframeWrapper = dynamic(() => import("../../molecules/iframeWrapper"))
const PinProtection = dynamic(() => import("../../organisms/pinProtection"))
const FlexibleCarousel = dynamic(() => import("../../organisms/flexibleCarousel"), { ssr: false })
const InterfaceSearch = dynamic(() => import("../../organisms/interfaceSearch"))
const MagazineLayout = dynamic(() => import("../../organisms/magazineLayout"))
const DecisionTree = dynamic(() => import("../../organisms/decisionTree"))
const ModulesInfoSection = dynamic(() => import("../../organisms/modulesInfoSection"))
const AcademyProgressChart = dynamic(
  () => import("../../molecules/highCharts/academyProgressChart"),
)
const AcademyModules = dynamic(() => import("../../organisms/academyModules"))

export const SkeletonBlock = () => (
  <Skeleton 
    className="skeleton-loader"
    duration={2}
    enableAnimation={true}
    height={300}
    baseColor="var(--brand-secondary-color-one)"
    highlightColor="var(--brand-grey-color-six)"
    containerClassName="skeleton-wrapper"
    count={1}
  />
)
export const HeroBannerBlock = ({ internalLayout, block }: BlockProps) => {
  const dispatch: Dispatch<any> = useDispatch()
  const { data: resp, isLoading } = useSWRRequest(block.url, async () => {
    try {
      const data = !block?.data ? await getBlockData(block?.type, block?.url, block?.background,
        block?.nid,
        block?.targetid,
        {}
      ) : block
      return data
    } catch (error) {
      console.error("[error] couldn't fetch block data ", error)
    }
  })
  
  if (isLoading) {
    return <SkeletonBlock />
  }

  // Assuming resp.fieldPaywallRoles is available when data is fetched
  if (resp && !isLoading) {
    dispatch(updatePaywall3Roles(Array.from(new Set(getFieldPaywallRoles(resp)))))
  }

  return (
    <>
      {!resp?.data?.isParentPaywalled && (
        <div>
          <HeroBanner internalLayout={internalLayout} {...resp?.data} />
          {internalLayout && <BrandingPattern />}
        </div>
      )}
    </>
  )
}

export const RotatingHeroBannerBlock = ({ block }: BlockProps) => {
  const dispatch: Dispatch<any> = useDispatch()
  const { data: resp, isLoading } = useSWRRequest(block.url, async () => {
    try {
      const data = !block?.data
        ? await getBlockData(
            block?.type,
            block?.url,
            block?.background,
            block?.nid,
            block?.targetid,
            {},
          )
        : block
      return data
    } catch (error) {
      console.error("[error] couldn't fetch block data ", error)
    }
  })

  if (isLoading) {
    return <SkeletonBlock />
  }

  // Assuming resp.fieldPaywallRoles is available when data is fetched
  if (resp && !isLoading) {
    dispatch(updatePaywall3Roles(Array.from(new Set(getFieldPaywallRoles(resp)))))
  }

  return (
    <>
      {!resp?.data?.isParentPaywalled && (
        <div>
          <RotatingHeroBanner {...resp?.data} />
          <BrandingPattern />
        </div>
      )}
    </>
  )
}

export const ArticleTeaserBannerBlock = ({ block }: BlockProps) => {
  const dispatch: Dispatch<any> = useDispatch()
  const { data: resp, isLoading } = useSWRRequest(block.url, async () => {
    try {
      const data = !block?.data ? await getBlockData(block?.type, block?.url, block?.background,
        block?.nid,
        block?.targetid,
        {}
      ) : block
      return data
    } catch (error) {
      console.error("[error] couldn't fetch block data ", error)
    }
  })

  if (isLoading) {
    return <SkeletonBlock />
  }

  // Assuming resp.fieldPaywallRoles is available when data is fetched
  if (resp && !isLoading) {
    dispatch(updatePaywall3Roles(Array.from(new Set(getFieldPaywallRoles(resp)))))
  }

  return (
    <>
      {!resp?.data?.isParentPaywalled && (
        <div>
          <ArticleTeaserBanner {...resp?.data} />
        </div>
      )}
    </>
  )
}

export const ProductCardsComponent = ({ block, colBackground, index, key }: BlockProps) => {
  const dispatch: Dispatch<any> = useDispatch()
  const { data: resp, isLoading } = useSWRRequest(block.url, async () => {
    try {
      const data = !block?.data ? await getBlockData(block?.type, block?.url, block?.background,
        block?.nid,
        block?.targetid,
        {}
      ) : block
      return data
    } catch (error) {
      console.error("[error] couldn't fetch block data ", error)
    }
  })

  if (isLoading) {
    return <SkeletonBlock />
  }

  // Assuming resp.fieldPaywallRoles is available when data is fetched
  if (resp && !isLoading) {
    dispatch(updatePaywall3Roles(Array.from(new Set(getFieldPaywallRoles(resp)))))
  }

  return (
    <Container background={colBackground} key={`${index}-${key}`} className="product-card-gap">
      <Row isTemplateWrapper>
        <CarouselWrapper slideToShow={4}>
          {resp?.data?.productCards?.map(
            (
              prodCard: JSX.IntrinsicAttributes & IProductCardProps,
              _key: Key | null | undefined,
            ) => (
              <ProductCard {...prodCard} key={_key} />
            ),
          )}
        </CarouselWrapper>
      </Row>
    </Container>
  )
}

export const ContactCardsComponent = ({ block, colBackground, index, key }: BlockProps) => {
  const dispatch: Dispatch<any> = useDispatch()
  const { data: resp, isLoading } = useSWRRequest(block.url, async () => {
    try {
      const data = !block?.data ? await getBlockData(block?.type, block?.url, block?.background,
        block?.nid,
        block?.targetid,
        {}
      ) : block
      return data
    } catch (error) {
      console.error("[error] couldn't fetch block data ", error)
    }
  })

  if (isLoading) {
    return <SkeletonBlock />
  }

  // Assuming resp.fieldPaywallRoles is available when data is fetched
  if (resp && !isLoading) {
    dispatch(updatePaywall3Roles(Array.from(new Set(getFieldPaywallRoles(resp)))))
  }

  return (
    <>
      {!resp?.data?.isParentPaywalled && (
        <Container background={colBackground} key={`${index}-${key}`}>
          <Row
            className={`card-group-alignment-${resp?.data?.cardGroupAlignment}`}
            isTemplateWrapper>
            {resp?.data?.contactCardsData?.map(
              (
                contactCard: JSX.IntrinsicAttributes & IContactCardProps,
                _key: Key | null | undefined,
              ) => (
                <ContactCard {...contactCard} key={_key} />
              ),
            )}
          </Row>
        </Container>
      )}
    </>
  )
}

export const IconCardsGroupComponent = ({ block, colBackground, index, key }: BlockProps) => {
  const dispatch: Dispatch<any> = useDispatch()
  const { data: resp, isLoading } = useSWRRequest(block.url, async () => {
    try {
      const data = !block?.data ? await getBlockData(block?.type, block?.url, block?.background,
        block?.nid,
        block?.targetid,
        {}
      ) : block
      return data
    } catch (error) {
      console.error("[error] couldn't fetch block data ", error)
    }
  })

  if (isLoading) {
    return <SkeletonBlock />
  }

  // Assuming resp.fieldPaywallRoles is available when data is fetched
  if (resp && !isLoading) {
    dispatch(updatePaywall3Roles(Array.from(new Set(getFieldPaywallRoles(resp)))))
  }

  return (
    <>
      {!resp?.data?.isParentPaywalled && (
        <Container background={colBackground} key={`${index}-${key}`}>
          <Row
            className={`card-group-alignment-${resp?.data?.cardGroupAlignment} ${
              resp?.data?.view === ICON_CARD_CONSTANTS.MINILIST ? "row-minlist" : ""
            }`}
            isTemplateWrapper>
            {resp?.data?.iconCards?.map(
              (iconCard: JSX.IntrinsicAttributes & IIconcardData, _key: Key | null | undefined) => (
                <IconCard
                  {...iconCard}
                  key={_key}
                  view={resp?.data?.view}
                  layout={resp?.data?.layout}
                  bulletPointsAlignment={resp?.data?.bulletPointsAlignment}
                />
              ),
            )}
          </Row>
        </Container>
      )}
    </>
  )
}

export const VideoCardsComponent = ({
  block,
  colBackground,
  index,
  key,
  applicationConfiguration,
}: BlockProps) => {
  const dispatch: Dispatch<any> = useDispatch()
  const { data: resp, isLoading } = useSWRRequest(block.url, async () => {
    try {
      const data = !block?.data ? await getBlockData(block?.type, block?.url, block?.background,
        block?.nid,
        block?.targetid,
        {}
      ) : block
      return data
    } catch (error) {
      console.error("[error] couldn't fetch block data ", error)
    }
  })

  if (isLoading) {
    return <SkeletonBlock />
  }

  // Assuming resp.fieldPaywallRoles is available when data is fetched
  if (resp && !isLoading) {
    dispatch(updatePaywall3Roles(Array.from(new Set(getFieldPaywallRoles(resp)))))
  }

  return (
    <>
      {!resp?.data?.isParentPaywalled && (
        <Container background={colBackground} key={`${index}-${key}`}>
          <Row isTemplateWrapper>
            <CarouselWrapper slideToShow={3} infinite={false}>
              {resp?.data?.videoTeaserCards?.map(
                (videoCard: JSX.IntrinsicAttributes & IVideoTeaserCardProps, _key: number) => (
                  <VideoTeaserCard
                    {...videoCard}
                    key={_key}
                    video_configuration={applicationConfiguration?.video_configuration}
                    cardNumber={videoCard?.id}
                  />
                ),
              )}
            </CarouselWrapper>
          </Row>
        </Container>
      )}
    </>
  )
}

export const SupportMaterialTeaserComponent = ({
  block,
  colBackground,
  index,
  key,
}: BlockProps) => {
  const dispatch: Dispatch<any> = useDispatch()
  const { data: resp, isLoading } = useSWRRequest(block.url, async () => {
    try {
      const data = !block?.data ? await getBlockData(block?.type, block?.url, block?.background,
        block?.nid,
        block?.targetid,
        {}
      ) : block
      return data
    } catch (error) {
      console.error("[error] couldn't fetch block data ", error)
    }
  })

  if (isLoading) {
    return <SkeletonBlock />
  }

  // Assuming resp.fieldPaywallRoles is available when data is fetched
  if (resp && !isLoading) {
    dispatch(updatePaywall3Roles(Array.from(new Set(getFieldPaywallRoles(resp)))))
  }

  return (
    <>
      {!resp?.data?.isParentPaywalled && (
        <Container
          className="material-teaser-card-group-wrapper"
          background={colBackground}
          key={`${index}-${key}`}>
          <Row isTemplateWrapper>
            <CarouselWrapper slideToShow={3}>
              {resp?.data?.supportMaterialCards?.map(
                (
                  materialCard: JSX.IntrinsicAttributes & IMaterialTeaserCardProps,
                  _key: Key | null | undefined,
                ) => (
                  <MaterialTeaserCard {...materialCard} key={_key} />
                ),
              )}
            </CarouselWrapper>
          </Row>
        </Container>
      )}
    </>
  )
}

export const OpinionLeadersTeaserComponent = ({ block, colBackground, index, key }: BlockProps) => {
  const dispatch: Dispatch<any> = useDispatch()
  const { data: resp, isLoading } = useSWRRequest(block.url, async () => {
    try {
      const data = !block?.data ? await getBlockData(block?.type, block?.url, block?.background,
        block?.nid,
        block?.targetid,
        {}
      ) : block
      return data
    } catch (error) {
      console.error("[error] couldn't fetch block data ", error)
    }
  })

  if (isLoading) {
    return <SkeletonBlock />
  }

  // Assuming resp.fieldPaywallRoles is available when data is fetched
  if (resp && !isLoading) {
    dispatch(updatePaywall3Roles(Array.from(new Set(getFieldPaywallRoles(resp)))))
  }

  return (
    <>
      {!resp?.data?.isParentPaywalled && (
        <Container background={colBackground} key={`${index}-${key}`}>
          <Row isTemplateWrapper>
            <CarouselWrapper slideToShow={3}>
              {resp?.data?.opinionCards?.map(
                (
                  opinionLeadersCard: JSX.IntrinsicAttributes & IOpinionLeadersCardProps,
                  _key: Key | null | undefined,
                ) => (
                  <OpinionLeadersCard {...opinionLeadersCard} key={_key} />
                ),
              )}
            </CarouselWrapper>
          </Row>
        </Container>
      )}
    </>
  )
}

export const ArticleTeaserCompositionBlock = ({ block, colBackground }: BlockProps) => {
  const { data: resp, isLoading } = useSWRRequest(block.url, async () => {
    try {
      const data = !block?.data ? await getBlockData(block?.type, block?.url, block?.background,
        block?.nid,
        block?.targetid,
        {}
      ) : block
      return data
    } catch (error) {
      console.error("[error] couldn't fetch block data ", error)
    }
  })

  if (isLoading) {
    return <SkeletonBlock />
  }

  const dispatch: Dispatch<any> = useDispatch()

  // Assuming resp.fieldPaywallRoles is available when data is fetched
  if (resp && !isLoading) {
    dispatch(updatePaywall3Roles(Array.from(new Set(getFieldPaywallRoles(resp)))))
  }

  const { data } = resp

  if (
    data?.containerDefault === EArticleTeaserContainerDefault.CONTAINER_NARROW ||
    data?.containerDefault === EArticleTeaserContainerDefault.CONTAINER_NORMAL
  ) {
    return (
      <>
        {!data?.isParentPaywalled && (
          <WrapperContainer subTheme={data?.subTheme}>
            <Container background={data?.subTheme ? "subTheme-colBg" : colBackground}>
              <Row isTemplateWrapper>
                <ArticleTeaserComposition {...data} />
              </Row>
            </Container>
          </WrapperContainer>
        )}
      </>
    )
  }
  if (data?.containerDefault === EArticleTeaserContainerDefault.CONTAINER_FLUID) {
    return (
      <>
        {!data?.isParentPaywalled && (
          <WrapperContainer subTheme={data?.subTheme}>
            <div>
              <ArticleTeaserFluid {...data} />
            </div>
          </WrapperContainer>
        )}
      </>
    )
  }

  return <></>
}

export const ArticleTeaserFluidBlock = ({ block }: BlockProps) => {
  const dispatch: Dispatch<any> = useDispatch()
  const { data: resp, isLoading } = useSWRRequest(block.url, async () => {
    try {
      const data = !block?.data ? await getBlockData(block?.type, block?.url, block?.background,
        block?.nid,
        block?.targetid,
        {}
      ) : block
      return data
    } catch (error) {
      console.error("[error] couldn't fetch block data ", error)
    }
  })

  if (isLoading) {
    return <SkeletonBlock />
  }

  // Assuming resp.fieldPaywallRoles is available when data is fetched
  if (resp && !isLoading) {
    dispatch(updatePaywall3Roles(Array.from(new Set(getFieldPaywallRoles(resp)))))
  }

  return (
    <div>
      <ArticleTeaserFluid {...resp?.data} />
    </div>
  )
}

export const TextBlockBlock = ({ block, colBackground }: BlockProps) => {
  const dispatch: Dispatch<any> = useDispatch()
  const { data: resp , isLoading } = useSWRRequest(block.url, async () => {
    try {
      const data = !block?.data ? await getBlockData(block?.type, block?.url, block?.background,
        block?.nid,
        block?.targetid,
        {}
      ) : block
      return data
    } catch (error) {
      console.error("[error] couldn't fetch block data ", error)
    }
  })

  if (isLoading) {
    return <SkeletonBlock />
  }

  // Assuming resp.fieldPaywallRoles is available when data is fetched
  if (resp && !isLoading) {
    dispatch(updatePaywall3Roles(Array.from(new Set(getFieldPaywallRoles(resp)))))
  }

  const containerType = resp?.data?.container ? resp?.data?.container : "normal"
  return (
    <>
      {!resp?.data?.isParentPaywalled && (
        <Container
          background={colBackground}
          narrow={containerType === "narrow"}
          fluid={containerType === "fluid"}>
          <Row isTemplateWrapper>
            <TextBlock {...resp?.data} />
          </Row>
        </Container>
      )}
    </>
  )
}

export const BreadcrumbsBlock = ({ block }: BlockProps) => {
  return (
    <div>
      <Breadcrumbs {...block?.data} />
    </div>
  )
}

export const GoogleMapBlock = ({ block }: BlockProps) => {
  const dispatch: Dispatch<any> = useDispatch()
  const { data: resp, isLoading } = useSWRRequest(block.url, async () => {
    try {
      const data = !block?.data ? await getBlockData(block?.type, block?.url, block?.background,
        block?.nid,
        block?.targetid,
        {}
      ) : block
      return data
    } catch (error) {
      console.error("[error] couldn't fetch block data ", error)
    }
  })

  if (isLoading) {
    return <SkeletonBlock />
  }

  // Assuming resp.fieldPaywallRoles is available when data is fetched
  if (resp && !isLoading) {
    dispatch(updatePaywall3Roles(Array.from(new Set(getFieldPaywallRoles(resp)))))
  }

  return (
    <>
      {!resp?.data?.isParentPaywalled && (
        <Row isTemplateWrapper className="event-map-snippet component-wrapper">
          <GoogleMap {...resp?.data} />
        </Row>
      )}
    </>
  )
}

export const ArticleTeaserGroupBlock = ({ block, colBackground }: BlockProps) => {
  const dispatch: Dispatch<any> = useDispatch()
  const { data: resp, isLoading } = useSWRRequest(block.url, async () => {
    try {
      const data = !block?.data ? await getBlockData(block?.type, block?.url, block?.background,
        block?.nid,
        block?.targetid,
        {}
      ) : block
      return data
    } catch (error) {
      console.error("[error] couldn't fetch block data ", error)
    }
  })

  if (isLoading) {
    return <SkeletonBlock />
  }

  // Assuming resp.fieldPaywallRoles is available when data is fetched
  if (resp && !isLoading) {
    dispatch(updatePaywall3Roles(Array.from(new Set(getFieldPaywallRoles(resp)))))
  }

  const containerType = resp?.data?.container ? resp?.data?.container : "normal"

  return (
    <>
      {!resp?.data?.isParentPaywalled && (
        <WrapperContainer subTheme={resp?.data?.subTheme}>
          <Container
            className="article-teaser-group-wrapper"
            background={resp?.data?.subTheme ? "subTheme-colBg" : colBackground}
            narrow={containerType === "narrow"}
            fluid={containerType === "fluid"}>
            <Row isTemplateWrapper>
              <ArticleTeaserGroup
                articleTeaserSingleContentGroup={resp?.data?.articleTeaserSingleContentGroup}
              />
            </Row>
          </Container>
        </WrapperContainer>
      )}
    </>
  )
}

export const ServiceTeaserCardBlock = ({ block, colBackground, index, key }: BlockProps) => {
  const dispatch: Dispatch<any> = useDispatch()
  const { data: resp, isLoading } = useSWRRequest(block.url, async () => {
    try {
      const data = !block?.data ? await getBlockData(block?.type, block?.url, block?.background,
        block?.nid,
        block?.targetid,
        {}
      ) : block
      return data
    } catch (error) {
      console.error("[error] couldn't fetch block data ", error)
    }
  })

  if (isLoading) {
    return <SkeletonBlock />
  }

  // Assuming resp.fieldPaywallRoles is available when data is fetched
  if (resp && !isLoading) {
    dispatch(updatePaywall3Roles(Array.from(new Set(getFieldPaywallRoles(resp)))))
  }

  return (
    <>
      {!resp?.data?.isParentPaywalled && (
        <Container
          background={colBackground}
          key={`${index}-${key}`}
          className="service-teaser-card-group-wrapper">
          <Row isTemplateWrapper>
            <CarouselWrapper slideToShow={3}>
              {resp?.data?.serviceCards?.map((serviceTeaserCard: any, _key: number) => (
                <ServiceTeaserCard key={_key} {...serviceTeaserCard} />
              ))}
            </CarouselWrapper>
          </Row>
        </Container>
      )}
    </>
  )
}

export const InterestingTeaserCardBlock = ({ block, colBackground, index, key }: BlockProps) => {
  const dispatch: Dispatch<any> = useDispatch()
  const { data: resp, isLoading } = useSWRRequest(block.url, async () => {
    try {
      const data = !block?.data ? await getBlockData(block?.type, block?.url, block?.background,
        block?.nid,
        block?.targetid,
        {}
      ) : block
      return data
    } catch (error) {
      console.error("[error] couldn't fetch block data ", error)
    }
  })

  if (isLoading) {
    return <SkeletonBlock />
  }

  // Assuming resp.fieldPaywallRoles is available when data is fetched
  if (resp && !isLoading) {
    dispatch(updatePaywall3Roles(Array.from(new Set(getFieldPaywallRoles(resp)))))
  }

  return (
    <>
      {!resp?.data?.isParentPaywalled && (
        <Container background={colBackground} key={`${index}-${key}`}>
          <Row isTemplateWrapper>
            <CarouselWrapper slideToShow={3}>
              {resp?.data?.contentCards?.map((interestingTeaserCard: any, _key: number) => (
                <InterestingTeaserCard key={_key} {...interestingTeaserCard} />
              ))}
            </CarouselWrapper>
          </Row>
        </Container>
      )}
    </>
  )
}

export const TabsBlock = ({ block, colBackground }: BlockProps) => {
  const dispatch: Dispatch<any> = useDispatch()
  const { data: resp, isLoading } = useSWRRequest(block.url, async () => {
    try {
      const data = !block?.data ? await getBlockData(block?.type, block?.url, block?.background,
        block?.nid,
        block?.targetid,
        {}
      ) : block
      return data
    } catch (error) {
      console.error("[error] couldn't fetch block data ", error)
    }
  })

  if (isLoading) {
    return <SkeletonBlock />
  }

  // Assuming resp.fieldPaywallRoles is available when data is fetched
  if (resp && !isLoading) {
    dispatch(updatePaywall3Roles(Array.from(new Set(getFieldPaywallRoles(resp)))))
  }

  const { data } = resp
  return (
    <Container background={colBackground}>
      <Row isTemplateWrapper>
        <Tabs {...data} />
      </Row>
    </Container>
  )
}

export const AccordionBlock = ({ block, colBackground }: BlockProps) => {
  const dispatch: Dispatch<any> = useDispatch()
  const { data: resp, isLoading } = useSWRRequest(block.url, async () => {
    try {
      const data = !block?.data ? await getBlockData(block?.type, block?.url, block?.background,
        block?.nid,
        block?.targetid,
        {}
      ) : block
      return data
    } catch (error) {
      console.error("[error] couldn't fetch block data ", error)
    }
  })

  if (isLoading) {
    return <SkeletonBlock />
  }

  // Assuming resp.fieldPaywallRoles is available when data is fetched
  if (resp && !isLoading) {
    dispatch(updatePaywall3Roles(Array.from(new Set(getFieldPaywallRoles(resp)))))
  }

  return (
    <>
      {!resp?.data?.isParentPaywalled && (
        <Container background={colBackground}>
          <Row isTemplateWrapper>
            {resp?.data?.accordionWithImage ? (
              <AccordionWithImage {...resp?.data} />
            ) : (
              <Accordion {...resp?.data} />
            )}
          </Row>
        </Container>
      )}
    </>
  )
}

export const AccordionAdvancedComponent = ({ block, colBackground }: BlockProps) => {
  const dispatch: Dispatch<any> = useDispatch()
  const { data: resp, isLoading } = useSWRRequest(block.url, async () => {
    try {
      const data = !block?.data ? await getBlockData(block?.type, block?.url, block?.background,
        block?.nid,
        block?.targetid,
        {}
      ) : block
      return data
    } catch (error) {
      console.error("[error] couldn't fetch block data ", error)
    }
  })

  if (isLoading) {
    return <SkeletonBlock />
  }

  return (
    <Container background={colBackground}>
      <Row isTemplateWrapper>
        <AccordionAdvanced {...resp?.data} />
      </Row>
    </Container>
  )
}

export const NewsStoriesCardBlock = ({ block, colBackground }: BlockProps) => {
  const dispatch: Dispatch<any> = useDispatch()
  const { data: resp, isLoading } = useSWRRequest(block.url, async () => {
    try {
      const data = !block?.data ? await getBlockData(block?.type, block?.url, block?.background,
        block?.nid,
        block?.targetid,
        {}
      ) : block
      return data
    } catch (error) {
      console.error("[error] couldn't fetch block data ", error)
    }
  })

  if (isLoading) {
    return <SkeletonBlock />
  }

  // Assuming resp.fieldPaywallRoles is available when data is fetched
  if (resp && !isLoading) {
    dispatch(updatePaywall3Roles(Array.from(new Set(getFieldPaywallRoles(resp)))))
  }

  return (
    <Container background={colBackground}>
      <Row isTemplateWrapper>
        <CarouselWrapper slideToShow={3}>
          {resp?.data?.latestNewsStoriesCards.map(
            (
              latestNAScard: JSX.IntrinsicAttributes & INewsStoriesCardProps,
              index: Key | null | undefined,
            ) => (
              <NewsStoriesCard key={index} {...latestNAScard} />
            ),
          )}
        </CarouselWrapper>
      </Row>
    </Container>
  )
}

export const SnackbiteBlock = ({ block, colBackground }: BlockProps) => {
  const dispatch: Dispatch<any> = useDispatch()
  const { data: resp, isLoading } = useSWRRequest(block.url, async () => {
    try {
      const data = !block?.data ? await getBlockData(block?.type, block?.url, block?.background,
        block?.nid,
        block?.targetid,
        {}
      ) : block
      return data
    } catch (error) {
      console.error("[error] couldn't fetch block data ", error)
    }
  })

  if (isLoading) {
    return <SkeletonBlock />
  }

  // Assuming resp.fieldPaywallRoles is available when data is fetched
  if (resp && !isLoading) {
    dispatch(updatePaywall3Roles(Array.from(new Set(getFieldPaywallRoles(resp)))))
  }

  return (
    <>
      {!resp?.data?.isParentPaywalled && (
        <Container background={colBackground} narrow>
          <Row isTemplateWrapper>
            <Snackbite {...resp?.data} />
          </Row>
        </Container>
      )}
    </>
  )
}

export const TestimonialBlock = ({ block, colBackground }: BlockProps) => {
  const dispatch: Dispatch<any> = useDispatch()
  const { data: resp, isLoading } = useSWRRequest(block.url, async () => {
    try {
      const data = !block?.data ? await getBlockData(block?.type, block?.url, block?.background,
        block?.nid,
        block?.targetid,
        {}
      ) : block
      return data
    } catch (error) {
      console.error("[error] couldn't fetch block data ", error)
    }
  })

  if (isLoading) {
    return <SkeletonBlock />
  }

  // Assuming resp.fieldPaywallRoles is available when data is fetched
  if (resp && !isLoading) {
    dispatch(updatePaywall3Roles(Array.from(new Set(getFieldPaywallRoles(resp)))))
  }

  return (
    <>
      {!resp?.data?.isParentPaywalled && (
        <Container background={colBackground ?? "grey"} className="show-margin">
          <Row isTemplateWrapper>
            <Testimonial {...resp?.data} />
          </Row>
        </Container>
      )}
    </>
  )
}

export const MediaPlayerBlock = ({ block, colBackground }: BlockProps) => {
  const dispatch: Dispatch<any> = useDispatch()
  const { data: resp, isLoading } = useSWRRequest(block.url, async () => {
    try {
      const data = !block?.data ? await getBlockData(block?.type, block?.url, block?.background,
        block?.nid,
        block?.targetid,
        {}
      ) : block
      return data
    } catch (error) {
      console.error("[error] couldn't fetch block data ", error)
    }
  })

  if (isLoading) {
    return <SkeletonBlock />
  }

  // Assuming resp.fieldPaywallRoles is available when data is fetched
  if (resp && !isLoading) {
    dispatch(updatePaywall3Roles(Array.from(new Set(getFieldPaywallRoles(resp)))))
  }

  return (
    <>
      {!resp?.data?.isParentPaywalled && (
        <Container background={colBackground ?? "grey"} className="show-margin">
          <Row isTemplateWrapper>
            <MediaPlayer {...resp?.data} />
          </Row>
        </Container>
      )}
    </>
  )
}

export const WebstoreDetails = ({ block, colBackground }: BlockProps) => {
  const dispatch: Dispatch<any> = useDispatch()
  const { data: resp, isLoading } = useSWRRequest(block.url, async () => {
    try {
      const data = !block?.data ? await getBlockData(block?.type, block?.url, block?.background,
        block?.nid,
        block?.targetid,
        {}
      ) : block
      return data
    } catch (error) {
      console.error("[error] couldn't fetch block data ", error)
    }
  })

  if (isLoading) {
    return <SkeletonBlock />
  }

  // Assuming resp.fieldPaywallRoles is available when data is fetched
  if (resp && !isLoading) {
    dispatch(updatePaywall3Roles(Array.from(new Set(getFieldPaywallRoles(resp)))))
  }

  return (
    <>
      {!resp?.data?.isParentPaywalled && (
        <Container background={colBackground ?? "grey"}>
          <Row isTemplateWrapper>
            <WebStoreDetail {...resp?.data} />
          </Row>
        </Container>
      )}
    </>
  )
}

export const RightPanelBlock = ({ block, colBackground }: BlockProps) => {
  const dispatch: Dispatch<any> = useDispatch()
  const { data: resp, isLoading } = useSWRRequest(block.url, async () => {
    try {
      const data = !block?.data ? await getBlockData(block?.type, block?.url, block?.background,
        block?.nid,
        block?.targetid,
        {}
      ) : block
      return data
    } catch (error) {
      console.error("[error] couldn't fetch block data ", error)
    }
  })

  if (isLoading) {
    return <SkeletonBlock />
  }

  // Assuming resp.fieldPaywallRoles is available when data is fetched
  if (resp && !isLoading) {
    dispatch(updatePaywall3Roles(Array.from(new Set(getFieldPaywallRoles(resp)))))
  }

  return (
    <>
      {!resp?.data?.isParentPaywalled && (
        <Container background={colBackground}>
          <Row isTemplateWrapper>
            <RightPanel {...resp?.data} />
          </Row>
        </Container>
      )}
    </>
  )
}

export const QuickLinksBlock = ({ block, colBackground }: BlockProps) => {
  const dispatch: Dispatch<any> = useDispatch()
  const { data: resp, isLoading } = useSWRRequest(block.url, async () => {
    try {
      const data = !block?.data ? await getBlockData(block?.type, block?.url, block?.background,
        block?.nid,
        block?.targetid,
        {}
      ) : block
      return data
    } catch (error) {
      console.error("[error] couldn't fetch block data ", error)
    }
  })

  if (isLoading) {
    return <SkeletonBlock />
  }

  // Assuming resp.fieldPaywallRoles is available when data is fetched
  if (resp && !isLoading) {
    dispatch(updatePaywall3Roles(Array.from(new Set(getFieldPaywallRoles(resp)))))
  }

  return (
    <>
      {!resp?.data?.isParentPaywalled && (
        <Container background={colBackground ?? "white"}>
          <Row isTemplateWrapper>
            <QuickLinks {...resp?.data} />
          </Row>
        </Container>
      )}
    </>
  )
}

export const RelatedItemsBlock = ({ block, colBackground }: BlockProps) => {
  const dispatch: Dispatch<any> = useDispatch()
  const { data: resp, isLoading } = useSWRRequest(block.url, async () => {
    try {
      const data = !block?.data ? await getBlockData(block?.type, block?.url, block?.background,
        block?.nid,
        block?.targetid,
        {}
      ) : block
      return data
    } catch (error) {
      console.error("[error] couldn't fetch block data ", error)
    }
  })

  if (isLoading) {
    return <SkeletonBlock />
  }

  // Assuming resp.fieldPaywallRoles is available when data is fetched
  if (resp && !isLoading) {
    dispatch(updatePaywall3Roles(Array.from(new Set(getFieldPaywallRoles(resp)))))
  }

  const { data } = resp
  return (
    <Container background={colBackground ?? "grey"} className="show-margin">
      <Row isTemplateWrapper>
        <RelatedItems {...data} />
      </Row>
    </Container>
  )
}

export const LocationsBlock = ({ block, colBackground }: BlockProps) => {
  const dispatch: Dispatch<any> = useDispatch()
  const { data: resp, isLoading } = useSWRRequest(block.url, async () => {
    try {
      const data = !block?.data ? await getBlockData(block?.type, block?.url, block?.background,
        block?.nid,
        block?.targetid,
        {}
      ) : block
      return data
    } catch (error) {
      console.error("[error] couldn't fetch block data ", error)
    }
  })

  if (isLoading) {
    return <SkeletonBlock />
  }

  // Assuming resp.fieldPaywallRoles is available when data is fetched
  if (resp && !isLoading) {
    dispatch(updatePaywall3Roles(Array.from(new Set(getFieldPaywallRoles(resp)))))
  }

  const { data } = resp
  return (
    <Container background={colBackground ?? "white"}>
      <Row isTemplateWrapper>
        <Locations {...data} />
      </Row>
    </Container>
  )
}

export const StockTickerBlock = ({ block, colBackground }: BlockProps) => {
  const dispatch: Dispatch<any> = useDispatch()
  const { data: resp, isLoading } = useSWRRequest(block.url, async () => {
    try {
      const data = !block?.data ? await getBlockData(block?.type, block?.url, block?.background,
        block?.nid,
        block?.targetid,
        {}
      ) : block
      return data
    } catch (error) {
      console.error("[error] couldn't fetch block data ", error)
    }
  })

  if (isLoading) {
    return <SkeletonBlock />
  }

  // Assuming resp.fieldPaywallRoles is available when data is fetched
  if (resp && !isLoading) {
    dispatch(updatePaywall3Roles(Array.from(new Set(getFieldPaywallRoles(resp)))))
  }

  const { data } = resp
  return (
    <>
      {!data?.isParentPaywalled && (
        <Container background={colBackground}>
          <Row isTemplateWrapper>
            <StockTicker {...data} />
          </Row>
        </Container>
      )}
    </>
  )
}

export const ImageBlockBlock = ({ block, colBackground }: BlockProps) => {
  const dispatch: Dispatch<any> = useDispatch()
  const { data: resp, isLoading } = useSWRRequest(block.url, async () => {
    try {
      const data = !block?.data ? await getBlockData(block?.type, block?.url, block?.background,
        block?.nid,
        block?.targetid,
        {}
      ) : block
      return data
    } catch (error) {
      console.error("[error] couldn't fetch block data ", error)
    }
  })

  if (isLoading) {
    return <SkeletonBlock />
  }

  // Assuming resp.fieldPaywallRoles is available when data is fetched
  if (resp && !isLoading) {
    dispatch(updatePaywall3Roles(Array.from(new Set(getFieldPaywallRoles(resp)))))
  }

  const { data } = resp
  return (
    <>
      {!data?.isParentPaywalled && (
        <Container background={colBackground}>
          <Row isTemplateWrapper>
            <ImageBlock {...data} />
          </Row>
        </Container>
      )}
    </>
  )
}

export const TeaserFeatureCardBlock = ({ block, colBackground }: BlockProps) => {
  const dispatch: Dispatch<any> = useDispatch()
  const { data: resp, isLoading } = useSWRRequest(block.url, async () => {
    try {
      const data = !block?.data ? await getBlockData(block?.type, block?.url, block?.background,
        block?.nid,
        block?.targetid,
        {}
      ) : block
      return data
    } catch (error) {
      console.error("[error] couldn't fetch block data ", error)
    }
  })

  if (isLoading) {
    return <SkeletonBlock />
  }

  // Assuming resp.fieldPaywallRoles is available when data is fetched
  if (resp && !isLoading) {
    dispatch(updatePaywall3Roles(Array.from(new Set(getFieldPaywallRoles(resp)))))
  }

  const { data } = resp
  return (
    <>
      {!data?.isParentPaywalled && (
        <Container background={colBackground}>
          <Row isTemplateWrapper>
            <TeaserFeatureCard {...data} isSingleCard={true} />
          </Row>
        </Container>
      )}
    </>
  )
}

export const CommonSearchBlock = ({ block, colBackground }: BlockProps) => {
  const dispatch: Dispatch<any> = useDispatch()
  const { data: resp, isLoading } = useSWRRequest(block.url, async () => {
    try {
      const data = !block?.data ? await getBlockData(block?.type, block?.url, block?.background,
        block?.nid,
        block?.targetid,
        {}
      ) : block
      return data
    } catch (error) {
      console.error("[error] couldn't fetch block data ", error)
    }
  })

  if (isLoading) {
    return <SkeletonBlock />
  }

  // Assuming resp.fieldPaywallRoles is available when data is fetched
  if (resp && !isLoading) {
    dispatch(updatePaywall3Roles(Array.from(new Set(getFieldPaywallRoles(resp)))))
  }

  const { data } = resp
  return (
    <>
      {!data?.isParentPaywalled && (
        <Container background={colBackground}>
          <Row isTemplateWrapper>
            <CommonSearch {...data} />
          </Row>
        </Container>
      )}
    </>
  )
}

export const CompactStockTickerBlock = ({ block, colBackground }: BlockProps) => {
  const dispatch: Dispatch<any> = useDispatch()
  const { data: resp, isLoading } = useSWRRequest(block.url, async () => {
    try {
      const data = !block?.data ? await getBlockData(block?.type, block?.url, block?.background,
        block?.nid,
        block?.targetid,
        {}
      ) : block
      return data
    } catch (error) {
      console.error("[error] couldn't fetch block data ", error)
    }
  })

  if (isLoading) {
    return <SkeletonBlock />
  }

  // Assuming resp.fieldPaywallRoles is available when data is fetched
  if (resp && !isLoading) {
    dispatch(updatePaywall3Roles(Array.from(new Set(getFieldPaywallRoles(resp)))))
  }
  const { data } = resp
  return (
    <>
      {!data?.isParentPaywalled && (
        <Container background={colBackground}>
          <Row isTemplateWrapper>
            <CompactStockTicker {...data} />
          </Row>
        </Container>
      )}
    </>
  )
}

export const ProductDetailBlockBlock = ({ block, colBackground }: BlockProps) => {
  const dispatch: Dispatch<any> = useDispatch()
  const { data: resp, isLoading } = useSWRRequest(block.url, async () => {
    try {
      const data = !block?.data ? await getBlockData(block?.type, block?.url, block?.background,
        block?.nid,
        block?.targetid,
        {}
      ) : block
      return data
    } catch (error) {
      console.error("[error] couldn't fetch block data ", error)
    }
  })

  if (isLoading) {
    return <SkeletonBlock />
  }

  // Assuming resp.fieldPaywallRoles is available when data is fetched
  if (resp && !isLoading) {
    dispatch(updatePaywall3Roles(Array.from(new Set(getFieldPaywallRoles(resp)))))
  }

  const { data } = resp
  return (
    <Container background={colBackground} narrow>
      <Row isTemplateWrapper>
        <ProductDetailBlock {...data} />
      </Row>
    </Container>
  )
}

export const ProductCatalogueBlockBlock = ({ block, colBackground }: BlockProps) => {
  const dispatch: Dispatch<any> = useDispatch()
  const { data: resp, isLoading } = useSWRRequest(block.url, async () => {
    try {
      const data = !block?.data ? await getBlockData(block?.type, block?.url, block?.background,
        block?.nid,
        block?.targetid,
        {}
      ) : block
      return data
    } catch (error) {
      console.error("[error] couldn't fetch block data ", error)
    }
  })

  if (isLoading) {
    return <SkeletonBlock />
  }

  // Assuming resp.fieldPaywallRoles is available when data is fetched
  if (resp && !isLoading) {
    dispatch(updatePaywall3Roles(Array.from(new Set(getFieldPaywallRoles(resp)))))
  }
  const { data } = resp
  return (
    <Container background={colBackground}>
      <Row isTemplateWrapper>
        <ProductCatalogueBlock {...data} />
      </Row>
    </Container>
  )
}

export const TeaserBlockBlock = ({ block, colBackground }: BlockProps) => {
  const dispatch: Dispatch<any> = useDispatch()
  const { data: resp, isLoading } = useSWRRequest(block.url, async () => {
    try {
      const data = !block?.data ? await getBlockData(block?.type, block?.url, block?.background,
        block?.nid,
        block?.targetid,
        {}
      ) : block
      return data
    } catch (error) {
      console.error("[error] couldn't fetch block data ", error)
    }
  })

  if (isLoading) {
    return <SkeletonBlock />
  }

  // Assuming resp.fieldPaywallRoles is available when data is fetched
  if (resp && !isLoading) {
    dispatch(updatePaywall3Roles(Array.from(new Set(getFieldPaywallRoles(resp)))))
  }

  const containerType = resp?.data?.cardType === "teaser_snacbite" ? "narrow" : "normal"
  return (
    <>
      {!resp?.data?.isParentPaywalled && (
        <Container background={colBackground} narrow={containerType === "narrow"}>
          <Row isTemplateWrapper>
            <div>
              <TeaserBlock {...resp?.data} />
            </div>
          </Row>
        </Container>
      )}
    </>
  )
}

export const QuickLinkCardBlock = ({ block, colBackground }: BlockProps) => {
  const dispatch: Dispatch<any> = useDispatch()
  const { data: resp, isLoading } = useSWRRequest(block.url, async () => {
    try {
      const data = !block?.data ? await getBlockData(block?.type, block?.url, block?.background,
        block?.nid,
        block?.targetid,
        {}
      ) : block
      return data
    } catch (error) {
      console.error("[error] couldn't fetch block data ", error)
    }
  })

  if (isLoading) {
    return <SkeletonBlock />
  }

  // Assuming resp.fieldPaywallRoles is available when data is fetched
  if (resp && !isLoading) {
    dispatch(updatePaywall3Roles(Array.from(new Set(getFieldPaywallRoles(resp)))))
  }

  return (
    <>
      {!resp?.data?.isParentPaywalled && (
        <Container background={colBackground ?? "white"}>
          <Row isTemplateWrapper>
            <QuickLinkCard {...resp?.data} />
          </Row>
        </Container>
      )}
    </>
  )
}

export const ContactUsBlock = ({ block, colBackground }: BlockProps) => {
  const dispatch: Dispatch<any> = useDispatch()
  const { data: resp, isLoading } = useSWRRequest(block.url, async () => {
    try {
      const data = !block?.data ? await getBlockData(block?.type, block?.url, block?.background,
        block?.nid,
        block?.targetid,
        {}
      ) : block
      return data
    } catch (error) {
      console.error("[error] couldn't fetch block data ", error)
    }
  })

  if (isLoading) {
    return <SkeletonBlock />
  }

  // Assuming resp.fieldPaywallRoles is available when data is fetched
  if (resp && !isLoading) {
    dispatch(updatePaywall3Roles(Array.from(new Set(getFieldPaywallRoles(resp)))))
  }

  const { data } = resp
  return (
    <Container background={colBackground}>
      <Row isTemplateWrapper>
        <ContactUs {...data} />
      </Row>
    </Container>
  )
}

export const InlineQuotationsBlock = ({ block, colBackground }: BlockProps) => {
  const dispatch: Dispatch<any> = useDispatch()
  const { data: resp, isLoading } = useSWRRequest(block.url, async () => {
    try {
      const data = !block?.data ? await getBlockData(block?.type, block?.url, block?.background,
        block?.nid,
        block?.targetid,
        {}
      ) : block
      return data
    } catch (error) {
      console.error("[error] couldn't fetch block data ", error)
    }
  })

  if (isLoading) {
    return <SkeletonBlock />
  }

  // Assuming resp.fieldPaywallRoles is available when data is fetched
  if (resp && !isLoading) {
    dispatch(updatePaywall3Roles(Array.from(new Set(getFieldPaywallRoles(resp)))))
  }

  const { data } = resp
  return (
    <>
      {!data?.isParentPaywalled && (
        <Container background={colBackground}>
          <Row isTemplateWrapper>
            <InlineQuotations {...data} />
          </Row>
        </Container>
      )}
    </>
  )
}

export const CareerSearchFilterBlock = ({ block, colBackground }: BlockProps) => {
  return (
    <Container background={colBackground}>
      <Row isTemplateWrapper>
        <CareerSearchFilter />
      </Row>
    </Container>
  )
}

export const WebformBlock = ({ block, index, key, colBackground }: BlockProps) => {
  const dispatch: Dispatch<any> = useDispatch()
  const { data: resp, isLoading } = useSWRRequest(block.url, async () => {
    try {
      const data = !block?.data ? await getBlockData(block?.type, block?.url, block?.background,
        block?.nid,
        block?.targetid,
        {}
      ) : block
      return data
    } catch (error) {
      console.error("[error] couldn't fetch block data ", error)
    }
  })

  if (isLoading) {
    return <SkeletonBlock />
  }

  // Assuming resp.fieldPaywallRoles is available when data is fetched
  if (resp && !isLoading) {
    dispatch(updatePaywall3Roles(Array.from(new Set(getFieldPaywallRoles(resp)))))
  }

  const { applicationHeaderData } = useContext(ApplicationContext)
  const themeName = applicationHeaderData?.headerData?.headerTopSection?.themeName ?? ""
  if (resp.data.categoryType === "calculator") {
    return (
      <Container background="white" key={`${index}-${key}`}>
        <Row isTemplateWrapper>
          <DosageCalculator {...resp?.data} />
        </Row>
      </Container>
    )
  }
  return (
    <Container background={colBackground ?? "white"} key={`${index}-${key}`}>
      <Row isTemplateWrapper>
        <GenericWebForm theme={themeName} {...resp?.data} />
      </Row>
    </Container>
  )
}

export const ScheduleTabsBlock = ({ block, index, key, colBackground }: BlockProps) => {
  const dispatch: Dispatch<any> = useDispatch()
  const { data: resp, isLoading } = useSWRRequest(block.url, async () => {
    try {
      const data = !block?.data ? await getBlockData(block?.type, block?.url, block?.background,
        block?.nid,
        block?.targetid,
        {}
      ) : block
      return data
    } catch (error) {
      console.error("[error] couldn't fetch block data ", error)
    }
  })

  if (isLoading) {
    return <SkeletonBlock />
  }

  // Assuming resp.fieldPaywallRoles is available when data is fetched
  if (resp && !isLoading) {
    dispatch(updatePaywall3Roles(Array.from(new Set(getFieldPaywallRoles(resp)))))
  }

  const { data: scheduleData } = resp
  const tabHeadItems:
    | ITabItemProps[]
    | { children: any; itemID: string; active: boolean; tabDesign: string }[] = []

  scheduleData?.scheduleData?.forEach((tab: { question: any }, _tabKey: any) => {
    tabHeadItems.push({
      children: tab.question,
      itemID: `schedule-${_tabKey}`,
      active: true,
      tabDesign: "pills",
    })
  })

  return (
    <>
      {!resp?.data?.isParentPaywalled && (
        <Container background={colBackground} key={`${index}-${key}`}>
          <Row isTemplateWrapper>
            {scheduleData?.scheduleData?.scheduleHeader && (
              <h3 className="conference-schedule-header">
                {scheduleData?.scheduleData?.scheduleHeader}
              </h3>
            )}
            <div className="conference-schedule-tabs">
              <Tabs
                tabHeadItems={tabHeadItems}
                tabContentID="schedule"
                tabHeadClassName="tab-nav"
                tabContentClassName="event-conf-tabs-content"
                tabContents={scheduleData?.scheduleData?.map(
                  (data: { answer: string }, _contentKey: Key | null | undefined) => (
                    <div key={_contentKey}>
                      <RichText content={data?.answer} />
                    </div>
                  ),
                )}
              />
            </div>
          </Row>
        </Container>
      )}
    </>
  )
}

export const OtherNewsAndStoriesFliterBlock = ({
  block,
  index,
  key,
  colBackground,
}: BlockProps) => {
  const dispatch: Dispatch<any> = useDispatch()
  const { data: resp, isLoading } = useSWRRequest(block.url, async () => {
    try {
      const data = !block?.data ? await getBlockData(block?.type, block?.url, block?.background,
        block?.nid,
        block?.targetid,
        {}
      ) : block
      return data
    } catch (error) {
      console.error("[error] couldn't fetch block data ", error)
    }
  })

  if (isLoading) {
    return <SkeletonBlock />
  }

  // Assuming resp.fieldPaywallRoles is available when data is fetched
  if (resp && !isLoading) {
    dispatch(updatePaywall3Roles(Array.from(new Set(getFieldPaywallRoles(resp)))))
  }

  const {
    data: { nasFilterData, pagination },
  } = resp

  return (
    <Container background={colBackground} key={`${index}-${key}`}>
      <Row isTemplateWrapper>
        <AdvancedFilterBlock type="news" filterData={nasFilterData} />
      </Row>
    </Container>
  )
}

export const EventVerticalCardBlock = ({ block, index, key, colBackground }: BlockProps) => {
  const dispatch: Dispatch<any> = useDispatch()
  const { data: resp, isLoading } = useSWRRequest(block.url, async () => {
    try {
      const data = !block?.data ? await getBlockData(block?.type, block?.url, block?.background,
        block?.nid,
        block?.targetid,
        {}
      ) : block
      return data
    } catch (error) {
      console.error("[error] couldn't fetch block data ", error)
    }
  })

  if (isLoading) {
    return <SkeletonBlock />
  }

  // Assuming resp.fieldPaywallRoles is available when data is fetched
  if (resp && !isLoading) {
    dispatch(updatePaywall3Roles(Array.from(new Set(getFieldPaywallRoles(resp)))))
  }

  const {
    data: { eventVerticalFilterData, pagination },
  } = resp

  return (
    <>
      {!eventVerticalFilterData?.isParentPaywalled && (
        <Container background={colBackground} key={`${index}-${key}`}>
          <Row isTemplateWrapper>
            <AdvancedFilterBlock type="events" filterData={eventVerticalFilterData} />
          </Row>
        </Container>
      )}
    </>
  )
}

export const EventCalendarHorizontalBlock = ({ block, index, key, colBackground }: BlockProps) => {
  const dispatch: Dispatch<any> = useDispatch()
  const { data: resp, isLoading } = useSWRRequest(block.url, async () => {
    try {
      const data = !block?.data ? await getBlockData(block?.type, block?.url, block?.background,
        block?.nid,
        block?.targetid,
        {}
      ) : block
      return data
    } catch (error) {
      console.error("[error] couldn't fetch block data ", error)
    }
  })

  if (isLoading) {
    return <SkeletonBlock />
  }

  // Assuming resp.fieldPaywallRoles is available when data is fetched
  if (resp && !isLoading) {
    dispatch(updatePaywall3Roles(Array.from(new Set(getFieldPaywallRoles(resp)))))
  }

  return (
    <>
      {!resp?.data?.isParentPaywalled && (
        <Container background={colBackground} key={`${index}-${key}`}>
          <Row isTemplateWrapper>
            <EventCalendar {...resp.data} />
          </Row>
        </Container>
      )}
    </>
  )
}

export const CommonTabGroupBlock = ({ block, index, key, colBackground }: BlockProps) => {
  const dispatch: Dispatch<any> = useDispatch()
  const { data: resp, isLoading } = useSWRRequest(block.url, async () => {
    try {
      const data = !block?.data ? await getBlockData(block?.type, block?.url, block?.background,
        block?.nid,
        block?.targetid,
        {}
      ) : block
      return data
    } catch (error) {
      console.error("[error] couldn't fetch block data ", error)
    }
  })

  if (isLoading) {
    return <SkeletonBlock />
  }

  // Assuming resp.fieldPaywallRoles is available when data is fetched
  if (resp && !isLoading) {
    dispatch(updatePaywall3Roles(Array.from(new Set(getFieldPaywallRoles(resp)))))
  }

  const { tabHeaderItems, tabBodyContents, isParentPaywalled } = resp?.data

  return (
    <>
      {!isParentPaywalled && (
        <Container fluid background={colBackground} key={`${index}-${key}`}>
          <Row isTemplateWrapper>
            <FlexiTabs
              key={`${index}-${key}-tabs`}
              activeTab={undefined}
              tabHeadItems={tabHeaderItems}
              tabContents={tabBodyContents}
            />
          </Row>
        </Container>
      )}
    </>
  )
}

export const ButtonGroupBlock = ({
  block,
  index,
  key,
  colBackground,
  applicationConfiguration,
  bookmarksNodeId,
  handleBookmarks,
}: BlockProps) => {
  const dispatch: Dispatch<any> = useDispatch()
  const { data: resp, isLoading } = useSWRRequest(block.url, async () => {
    try {
      const data = !block?.data ? await getBlockData(block?.type, block?.url, block?.background,
        block?.nid,
        block?.targetid,
        {}
      ) : block
      return data
    } catch (error) {
      console.error("[error] couldn't fetch block data ", error)
    }
  })

  if (isLoading) {
    return <SkeletonBlock />
  }

  // Assuming resp.fieldPaywallRoles is available when data is fetched
  if (resp && !isLoading) {
    dispatch(updatePaywall3Roles(Array.from(new Set(getFieldPaywallRoles(resp)))))
  }

  return (
    <>
      {!resp?.data?.isParentPaywalled && (
        <Container background={colBackground} key={`${index}-${key}`}>
          <Row isTemplateWrapper>
            <ButtonGroup>
              {resp?.data?.buttons?.map(
                (
                  blockData: {
                    pageLinkLabel?: any
                    copiedLabel?: any
                    ctaBtnType?: any
                    ctaBtnData?: any
                    ctaBtnLabel?: any
                  },
                  i: Key | null | undefined,
                ) => {
                  const { ctaBtnType, ctaBtnData, ctaBtnLabel } = blockData
                  if (ctaBtnType === "bookmark" && applicationConfiguration?.isBookmarkEnabled) {
                    return (
                      <BookmarkWithButton
                        key={i}
                        id={bookmarksNodeId}
                        bookmarkCallback={handleBookmarks}
                        {...ctaBtnData}
                      />
                    )
                  }
                  if (ctaBtnType === "calendar") {
                    return <AddToCalendar key={i} {...ctaBtnData} btnLabel={ctaBtnLabel} />
                  }
                  if (ctaBtnType === "favourite" && applicationConfiguration?.isFavouriteEnabled) {
                    return (
                      <Button
                        key={i}
                        className="common-cta-favourite"
                        isSecondary
                        tabindex={key}
                        icon={<Icon iconName="FavouriteIcon" />}>
                        {ctaBtnLabel}
                      </Button>
                    )
                  }
                  if (ctaBtnType === "medicalinfo") {
                    return (
                      <Button key={i} className="common-cta-medicalinfo" isSecondary tabindex={key}>
                        {ctaBtnLabel}
                      </Button>
                    )
                  }
                  if (ctaBtnType === "download") {
                    return (
                      <AnchorButton
                        key={i}
                        className="anchor-cta-download"
                        isExternal
                        icon={<Icon iconName="DownloadIcon" />}
                        {...ctaBtnData}
                      />
                    )
                  }
                  if (ctaBtnType === "print") {
                    return (
                      <AnchorButton
                        key={i}
                        className="anchor-cta-print"
                        isExternal
                        icon={<Icon iconName="PrintIcon" />}
                        {...ctaBtnData}
                      />
                    )
                  }
                  if (ctaBtnType === "link") {
                    return (
                      <AnchorButton
                        key={i}
                        className="anchor-cta-link"
                        isExternal
                        {...ctaBtnData}
                      />
                    )
                  }
                  if (ctaBtnType === "social_sharing") {
                    return (
                      <SocialShare
                        key={i}
                        tabIndex={key}
                        sharingPlatforms={ctaBtnData}
                        btnLabel={ctaBtnLabel}
                        pageLinkLabel={blockData.pageLinkLabel}
                        copiedLabel={blockData.copiedLabel}
                      />
                    )
                  }
                  if (ctaBtnType === "cta_button") {
                    return (
                      <CTAButtonGroup
                        key={i}
                        tabIndex={key}
                        fieldButtonTitle={ctaBtnLabel}
                        isInline={resp?.data?.buttons?.length > NUMBERS.ONE ?? false}
                        {...ctaBtnData}
                      />
                    )
                  }
                },
              )}
            </ButtonGroup>
          </Row>
        </Container>
      )}
    </>
  )
}
export const ProductCardDetailBlock = ({ block, index, key, colBackground }: BlockProps) => {
  const dispatch: Dispatch<any> = useDispatch()
  const { data: resp, isLoading } = useSWRRequest(block.url, async () => {
    try {
      const data = !block?.data ? await getBlockData(block?.type, block?.url, block?.background,
        block?.nid,
        block?.targetid,
        {}
      ) : block
      return data
    } catch (error) {
      console.error("[error] couldn't fetch block data ", error)
    }
  })

  if (isLoading) {
    return <SkeletonBlock />
  }

  // Assuming resp.fieldPaywallRoles is available when data is fetched
  if (resp && !isLoading) {
    dispatch(updatePaywall3Roles(Array.from(new Set(getFieldPaywallRoles(resp)))))
  }

  return (
    <>
      {!resp?.data?.isParentPaywalled && (
        <Container background={colBackground} key={`${index}-${key}`}>
          <Row isTemplateWrapper>
            <DetailBlock {...resp?.data} />
          </Row>
        </Container>
      )}
    </>
  )
}

export const PostCodeLookupBlock = ({ block }: BlockProps) => {
  const dispatch: Dispatch<any> = useDispatch()
  const { data: resp, isLoading } = useSWRRequest(block.url, async () => {
    try {
      const data = !block?.data ? await getBlockData(block?.type, block?.url, block?.background,
        block?.nid,
        block?.targetid,
        {}
      ) : block
      return data
    } catch (error) {
      console.error("[error] couldn't fetch block data ", error)
    }
  })

  if (isLoading) {
    return <SkeletonBlock />
  }

  // Assuming resp.fieldPaywallRoles is available when data is fetched
  if (resp && !isLoading) {
    dispatch(updatePaywall3Roles(Array.from(new Set(getFieldPaywallRoles(resp)))))
  }

  return (
    <div>
      <PostcodeSearchFunction {...resp.data} />
    </div>
  )
}

export const TherapyCardsBlock = ({ block, index, key, colBackground }: BlockProps) => {
  const dispatch: Dispatch<any> = useDispatch()
  const { data: resp, isLoading } = useSWRRequest(block.url, async () => {
    try {
      const data = !block?.data ? await getBlockData(block?.type, block?.url, block?.background,
        block?.nid,
        block?.targetid,
        {}
      ) : block
      return data
    } catch (error) {
      console.error("[error] couldn't fetch block data ", error)
    }
  })

  if (isLoading) {
    return <SkeletonBlock />
  }

  // Assuming resp.fieldPaywallRoles is available when data is fetched
  if (resp && !isLoading) {
    dispatch(updatePaywall3Roles(Array.from(new Set(getFieldPaywallRoles(resp)))))
  }

  return (
    <Container background={colBackground} key={`${index}-${key}`}>
      <Row isTemplateWrapper>
        <AdvancedFilterBlock type="therapy-areas" filterData={resp?.data?.therapyFilterData} />
      </Row>
    </Container>
  )
}

export const OtherNewsAndStoriesBlock = ({ block, index, key, colBackground }: BlockProps) => {
  const dispatch: Dispatch<any> = useDispatch()
  const { data: resp, isLoading } = useSWRRequest(block.url, async () => {
    try {
      const data = !block?.data ? await getBlockData(block?.type, block?.url, block?.background,
        block?.nid,
        block?.targetid,
        {}
      ) : block
      return data
    } catch (error) {
      console.error("[error] couldn't fetch block data ", error)
    }
  })

  if (isLoading) {
    return <SkeletonBlock />
  }

  // Assuming resp.fieldPaywallRoles is available when data is fetched
  if (resp && !isLoading) {
    dispatch(updatePaywall3Roles(Array.from(new Set(getFieldPaywallRoles(resp)))))
  }

  return (
    <>
      {!resp?.data?.isParentPaywalled && (
        <Container background={colBackground} key={`${index}-${key}`}>
          <Row isTemplateWrapper>
            <CarouselWrapper slideToShow={1} slideToShowTablet={1}>
              {resp?.data?.newsData?.map(
                (
                  articleTeaserCards: JSX.IntrinsicAttributes & IArticleTeaserProps,
                  _key: Key | null | undefined,
                ) => (
                  <ArticleTeaserComposition key={_key} {...articleTeaserCards} />
                ),
              )}
            </CarouselWrapper>
          </Row>
        </Container>
      )}
    </>
  )
}

export const TableReferenceColumnBlock = ({ block, index, key, colBackground }: BlockProps) => {
  const dispatch: Dispatch<any> = useDispatch()
  const { data: resp, isLoading } = useSWRRequest(block.url, async () => {
    try {
      const data = !block?.data ? await getBlockData(block?.type, block?.url, block?.background,
        block?.nid,
        block?.targetid,
        {}
      ) : block
      return data
    } catch (error) {
      console.error("[error] couldn't fetch block data ", error)
    }
  })

  if (isLoading) {
    return <SkeletonBlock />
  }

  // Assuming resp.fieldPaywallRoles is available when data is fetched
  if (resp && !isLoading) {
    dispatch(updatePaywall3Roles(Array.from(new Set(getFieldPaywallRoles(resp)))))
  }

  return (
    <>
      {!resp?.data?.isParentPaywalled && (
        <Container background={colBackground} key={`${index}-${key}`}>
          <Row isTemplateWrapper>
            <ComparisonTable {...resp?.data} />
          </Row>
        </Container>
      )}
    </>
  )
}

export const MediaLibraryDetailBlock = ({ block, index, key, colBackground }: BlockProps) => {
  const dispatch: Dispatch<any> = useDispatch()
  const { data: resp, isLoading } = useSWRRequest(block.url, async () => {
    try {
      const data = !block?.data ? await getBlockData(block?.type, block?.url, block?.background,
        block?.nid,
        block?.targetid,
        {}
      ) : block
      return data
    } catch (error) {
      console.error("[error] couldn't fetch block data ", error)
    }
  })

  if (isLoading) {
    return <SkeletonBlock />
  }

  // Assuming resp.fieldPaywallRoles is available when data is fetched
  if (resp && !isLoading) {
    dispatch(updatePaywall3Roles(Array.from(new Set(getFieldPaywallRoles(resp)))))
  }

  return (
    <>
      {!resp?.data?.isParentPaywalled && (
        <Container background={colBackground} key={`${index}-${key}`}>
          <Row isTemplateWrapper>
            <MediaLibraryDetails {...resp?.data} />
          </Row>
        </Container>
      )}
    </>
  )
}

export const MediaLibrarySearchBlock = ({ block, index, key, colBackground }: BlockProps) => {
  const dispatch: Dispatch<any> = useDispatch()
  const { data: resp, isLoading } = useSWRRequest(block.url, async () => {
    try {
      const data = !block?.data ? await getBlockData(block?.type, block?.url, block?.background,
        block?.nid,
        block?.targetid,
        {}
      ) : block
      return data
    } catch (error) {
      console.error("[error] couldn't fetch block data ", error)
    }
  })

  if (isLoading) {
    return <SkeletonBlock />
  }

  // Assuming resp.fieldPaywallRoles is available when data is fetched
  if (resp && !isLoading) {
    dispatch(updatePaywall3Roles(Array.from(new Set(getFieldPaywallRoles(resp)))))
  }

  return (
    <Container background={colBackground} key={`${index}-${key}`}>
      <MediaLibrarySearch mediaData={resp?.data} />
    </Container>
  )
}

export const MediaRelatedItemsBlock = ({ block, index, key, colBackground }: BlockProps) => {
  const dispatch: Dispatch<any> = useDispatch()
  const { data: resp, isLoading } = useSWRRequest(block.url, async () => {
    try {
      const data = !block?.data ? await getBlockData(block?.type, block?.url, block?.background,
        block?.nid,
        block?.targetid,
        {}
      ) : block
      return data
    } catch (error) {
      console.error("[error] couldn't fetch block data ", error)
    }
  })

  if (isLoading) {
    return <SkeletonBlock />
  }

  // Assuming resp.fieldPaywallRoles is available when data is fetched
  if (resp && !isLoading) {
    dispatch(updatePaywall3Roles(Array.from(new Set(getFieldPaywallRoles(resp)))))
  }

  return (
    <Container background={colBackground ?? "grey"} className="show-margin" key={`${index}-${key}`}>
      <Row isTemplateWrapper>
        <RelatedItems {...resp?.data} />
      </Row>
    </Container>
  )
}

export const NewsSearchBlock = ({ block, index, key, colBackground }: BlockProps) => {
  const dispatch: Dispatch<any> = useDispatch()
  const { data: resp, isLoading } = useSWRRequest(block.url, async () => {
    try {
      const data = !block?.data ? await getBlockData(block?.type, block?.url, block?.background,
        block?.nid,
        block?.targetid,
        {}
      ) : block
      return data
    } catch (error) {
      console.error("[error] couldn't fetch block data ", error)
    }
  })

  if (isLoading) {
    return <SkeletonBlock />
  }

  // Assuming resp.fieldPaywallRoles is available when data is fetched
  if (resp && !isLoading) {
    dispatch(updatePaywall3Roles(Array.from(new Set(getFieldPaywallRoles(resp)))))
  }

  return (
    <Container background={colBackground} key={`${index}-${key}`}>
      <NewsFilterSearch {...resp?.data} />
    </Container>
  )
}

export const CsvProductBlock = ({ block, colBackground }: BlockProps) => {
  const dispatch: Dispatch<any> = useDispatch()
  const { data: resp, isLoading } = useSWRRequest(block.url, async () => {
    try {
      const data = !block?.data ? await getBlockData(block?.type, block?.url, block?.background,
        block?.nid,
        block?.targetid,
        {}
      ) : block
      return data
    } catch (error) {
      console.error("[error] couldn't fetch block data ", error)
    }
  })

  if (isLoading) {
    return <SkeletonBlock />
  }

  // Assuming resp.fieldPaywallRoles is available when data is fetched
  if (resp && !isLoading) {
    dispatch(updatePaywall3Roles(Array.from(new Set(getFieldPaywallRoles(resp)))))
  }

  return (
    <Container background={colBackground} narrow>
      <ProductDetailBlock {...resp?.data} />
    </Container>
  )
}

export const RestDataExportLinkBlock = ({
  block,
  applicationConfiguration,
  colBackground,
}: BlockProps) => {
  const dispatch: Dispatch<any> = useDispatch()
  const { data: resp, isLoading } = useSWRRequest(block.url, async () => {
    try {
      const data = !block?.data ? await getBlockData(block?.type, block?.url, block?.background,
        block?.nid,
        block?.targetid,
        {}
      ) : block
      return data
    } catch (error) {
      console.error("[error] couldn't fetch block data ", error)
    }
  })

  if (isLoading) {
    return <SkeletonBlock />
  }

  // Assuming resp.fieldPaywallRoles is available when data is fetched
  if (resp && !isLoading) {
    dispatch(updatePaywall3Roles(Array.from(new Set(getFieldPaywallRoles(resp)))))
  }

  if (resp?.data?.type === "product_catalogue") {
    return (
      <Container background={colBackground}>
        <Row isTemplateWrapper>
          <ProductCatalogueBlock {...resp?.data?.data} />
        </Row>
      </Container>
    )
  }

  if (resp?.data?.type === "product_listing" || resp?.data?.type === "pim_product_listing") {
    return (
      <Container background={colBackground} className="csv-prod-listing">
        <ProductListV2 {...resp?.data?.data} type={resp?.data?.type} />
      </Container>
    )
  }

  if (resp?.data?.type === "simple_product") {
    return (
      <Container background={colBackground} className="prod-listing">
        <ProductList {...resp?.data} dispalyData={applicationConfiguration?.wave1_product_data} />
      </Container>
    )
  }
  if (resp?.data?.type === "pim_family_page") {
    return (
      <Container background={colBackground ?? "white"}>
        <Row isTemplateWrapper>
          <ProductFamilyList {...resp.data} />
        </Row>
      </Container>
    )
  }
  if (resp?.data?.type === "category_banner") {
    return <ProductCategoryBanner {...resp.data} />
  }
  if (resp?.data?.type === "sendout_interface_one") {
    return (
      <Container>
        <InterfaceSearch {...resp.data} />
      </Container>
    )
  }
  if (resp?.data?.type === "sendout_interface_two") {
    return (
      <Container>
        <Row isTemplateWrapper>
          <InterfaceSearch {...resp.data} />
        </Row>
      </Container>
    )
  }
  if (block?.data?.type === "cc_po_listing") {
    return (
      <Container className="po-listings">
        <Row isTemplateWrapper>
          <PurchaseOrderSearchFilter {...block?.data} />
        </Row>
      </Container>
    )
  }
  if (block?.data?.type === "cc_po_details_page") {
    return (
      <Container className="purchase-detail">
        <Row isTemplateWrapper>
          <PurchaseOrderDetailFilter {...block?.data} />
        </Row>
      </Container>
    )
  }
  if (block?.data?.type === "cc_company_listing") {
    return (
      <Container className="company-listing">
        <Row isTemplateWrapper>
          <CompanySearchFilter {...block?.data} />
        </Row>
      </Container>
    )
  }
  if (block?.data?.type === "cc_user_action_listing") {
    return (
      <Container className="user-details">
        <Row isTemplateWrapper>
          <UserAccountFilter {...block?.data} />
        </Row>
      </Container>
    )
  }
  if (block?.data?.type === "cc_openorder_listing") {
    return (
      <Container className="open-orders">
        <Row isTemplateWrapper>
          <OpenOrderSearchFilter {...block?.data} />
        </Row>
      </Container>
    )
  }
  return <></>
}

export const MenuLinkWithIconGroupBlock = ({ block, index, key, colBackground }: BlockProps) => {
  const dispatch: Dispatch<any> = useDispatch()
  const { data: resp, isLoading } = useSWRRequest(block.url, async () => {
    try {
      const data = !block?.data ? await getBlockData(block?.type, block?.url, block?.background,
        block?.nid,
        block?.targetid,
        {}
      ) : block
      return data
    } catch (error) {
      console.error("[error] couldn't fetch block data ", error)
    }
  })

  if (isLoading) {
    return <SkeletonBlock />
  }

  // Assuming resp.fieldPaywallRoles is available when data is fetched
  if (resp && !isLoading) {
    dispatch(updatePaywall3Roles(Array.from(new Set(getFieldPaywallRoles(resp)))))
  }

  return (
    <>
      {!resp?.data?.isParentPaywalled && (
        <Container background={colBackground} key={`${index}-${key}`}>
          <Row isTemplateWrapper>
            <LinkWithIcon {...resp?.data} />
          </Row>
        </Container>
      )}
    </>
  )
}

export const PersonaPatientProfileBlock = ({ block, index, key, colBackground }: BlockProps) => {
  const dispatch: Dispatch<any> = useDispatch()
  const { data: resp, isLoading } = useSWRRequest(block.url, async () => {
    try {
      const data = !block?.data ? await getBlockData(block?.type, block?.url, block?.background,
        block?.nid,
        block?.targetid,
        {}
      ) : block
      return data
    } catch (error) {
      console.error("[error] couldn't fetch block data ", error)
    }
  })

  if (isLoading) {
    return <SkeletonBlock />
  }

  // Assuming resp.fieldPaywallRoles is available when data is fetched
  if (resp && !isLoading) {
    dispatch(updatePaywall3Roles(Array.from(new Set(getFieldPaywallRoles(resp)))))
  }

  return (
    <>
      {!resp?.data?.isParentPaywalled && (
        <Container background={colBackground} key={`${index}-${key}`}>
          <Row isTemplateWrapper>
            <PatientProfile {...resp?.data} />
          </Row>
        </Container>
      )}
    </>
  )
}

export const EducationOverviewBlock = ({ block, colBackground, index, key }: BlockProps) => {
  const dispatch: Dispatch<any> = useDispatch()
  const { data: resp, isLoading } = useSWRRequest(block.url, async () => {
    try {
      const data = !block?.data ? await getBlockData(block?.type, block?.url, block?.background,
        block?.nid,
        block?.targetid,
        {}
      ) : block
      return data
    } catch (error) {
      console.error("[error] couldn't fetch block data ", error)
    }
  })

  if (isLoading) {
    return <SkeletonBlock />
  }

  // Assuming resp.fieldPaywallRoles is available when data is fetched
  if (resp && !isLoading) {
    dispatch(updatePaywall3Roles(Array.from(new Set(getFieldPaywallRoles(resp)))))
  }

  return (
    <Container background={colBackground} key={`${index}-${key}`}>
      <Row isTemplateWrapper>
        <AdvancedFilterBlock
          type="education"
          filterData={resp?.data?.educationAdvanceFilterData}
        />
      </Row>
    </Container>
  )
}

export const AcademyCatalogueBlock = ({ block, colBackground, index, key }: BlockProps) => {
  const {
    data: { courseCatalogueAdvanceFilterData, pagination, type },
  } = block
  return (
    <Container background={colBackground} key={`${index}-${key}`}>
      <Row isTemplateWrapper>
        <AdvancedFilterBlock
          type={block?.type}
          filterData={courseCatalogueAdvanceFilterData}
        />
      </Row>
    </Container>
  )
}

export const RelatedContentBlock = ({ block, colBackground }: BlockProps) => {
  const dispatch: Dispatch<any> = useDispatch()
  const { data: resp, isLoading } = useSWRRequest(block.url, async () => {
    try {
      const data = !block?.data ? await getBlockData(block?.type, block?.url, block?.background,
        block?.nid,
        block?.targetid,
        {}
      ) : block
      return data
    } catch (error) {
      console.error("[error] couldn't fetch block data ", error)
    }
  })

  if (isLoading) {
    return <SkeletonBlock />
  }

  // Assuming resp.fieldPaywallRoles is available when data is fetched
  if (resp && !isLoading) {
    dispatch(updatePaywall3Roles(Array.from(new Set(getFieldPaywallRoles(resp)))))
  }

  return (
    <div className="component-wrapper">
      <RelatedContent {...resp?.data} background={colBackground} />
    </div>
  )
}

export const WebstoreProductSearchBlock = ({
  block,
  index,
  key,
  colBackground,
  applicationConfiguration,
}: BlockProps) => {
  const dispatch: Dispatch<any> = useDispatch()
  const { data: resp, isLoading } = useSWRRequest(block.url, async () => {
    try {
      const data = !block?.data ? await getBlockData(block?.type, block?.url, block?.background,
        block?.nid,
        block?.targetid,
        {}
      ) : block
      return data
    } catch (error) {
      console.error("[error] couldn't fetch block data ", error)
    }
  })

  if (isLoading) {
    return <SkeletonBlock />
  }

  return (
    applicationConfiguration?.isWebStoreEnabled && (
      <Container background={colBackground}>
        <Row isTemplateWrapper>
          <WebshopSearchFilter {...resp?.data} />
        </Row>
      </Container>
    )
  )
}

export const newsLetterSignUpBlock = ({ block, index, key, colBackground }: BlockProps) => {
  const dispatch: Dispatch<any> = useDispatch()
  const { data: resp, isLoading } = useSWRRequest(block.url, async () => {
    try {
      const data = !block?.data ? await getBlockData(block?.type, block?.url, block?.background,
        block?.nid,
        block?.targetid,
        {}
      ) : block
      return data
    } catch (error) {
      console.error("[error] couldn't fetch block data ", error)
    }
  })

  if (isLoading) {
    return <SkeletonBlock />
  }

  // Assuming resp.fieldPaywallRoles is available when data is fetched
  if (resp && !isLoading) {
    dispatch(updatePaywall3Roles(Array.from(new Set(getFieldPaywallRoles(resp)))))
  }

  return (
    <Container background={colBackground ?? "white"} key={`${index}-${key}`}>
      <Row isTemplateWrapper>
        <NewsLetterSignUp {...resp.data} />
      </Row>
    </Container>
  )
}

export const EmbedGoogleMapsBlock = ({ block, index, key, colBackground }: BlockProps) => {
  const dispatch: Dispatch<any> = useDispatch()
  const { data: resp, isLoading } = useSWRRequest(block.url, async () => {
    try {
      const data = !block?.data ? await getBlockData(block?.type, block?.url, block?.background,
        block?.nid,
        block?.targetid,
        {}
      ) : block
      return data
    } catch (error) {
      console.error("[error] couldn't fetch block data ", error)
    }
  })

  if (isLoading) {
    return <SkeletonBlock />
  }

  // Assuming resp.fieldPaywallRoles is available when data is fetched
  if (resp && !isLoading) {
    dispatch(updatePaywall3Roles(Array.from(new Set(getFieldPaywallRoles(resp)))))
  }

  return (
    <>
      {!resp?.data?.isParentPaywalled && (
        <Container background={colBackground ?? "white"} key={`${index}-${key}`}>
          <Row isTemplateWrapper>
            <EmbedMap {...resp?.data} />
          </Row>
        </Container>
      )}
    </>
  )
}

export const SolarTableBlock = ({ block, colBackground }: BlockProps) => {
  const dispatch: Dispatch<any> = useDispatch()
  const { data: resp, isLoading } = useSWRRequest(block.url, async () => {
    try {
      const data = !block?.data ? await getBlockData(block?.type, block?.url, block?.background,
        block?.nid,
        block?.targetid,
        {}
      ) : block
      return data
    } catch (error) {
      console.error("[error] couldn't fetch block data ", error)
    }
  })

  if (isLoading) {
    return <SkeletonBlock />
  }

  // Assuming resp.fieldPaywallRoles is available when data is fetched
  if (resp && !isLoading) {
    dispatch(updatePaywall3Roles(Array.from(new Set(getFieldPaywallRoles(resp)))))
  }

  return (
    <>
      {!resp?.data?.isParentPaywalled && (
        <Container background={colBackground}>
          <Row className={`solar-tbl-${resp?.data?.tableAlignment}`} isTemplateWrapper>
            <SolarTableEnhanced {...resp?.data} />
          </Row>
        </Container>
      )}
    </>
  )
}

export const PrintButtonBlock = ({ block, colBackground, index, key }: BlockProps) => {
  const dispatch: Dispatch<any> = useDispatch()
  const { data: resp, isLoading } = useSWRRequest(block.url, async () => {
    try {
      const data = !block?.data ? await getBlockData(block?.type, block?.url, block?.background,
        block?.nid,
        block?.targetid,
        {}
      ) : block
      return data
    } catch (error) {
      console.error("[error] couldn't fetch block data ", error)
    }
  })

  if (isLoading) {
    return <SkeletonBlock />
  }

  // Assuming resp.fieldPaywallRoles is available when data is fetched
  if (resp && !isLoading) {
    dispatch(updatePaywall3Roles(Array.from(new Set(getFieldPaywallRoles(resp)))))
  }

  if (resp?.type === "print_version_configuration" && !resp?.data?.isParentPaywalled) {
    return (
      <Container background={colBackground ?? "white"} key={`${index}-${key}`}>
        <Row isTemplateWrapper>
          <div className={`print-button ${resp?.data?.alignment}`}>
            <AnchorButton
              icon={<Icon iconName="PrintIcon" />}
              href="javascript:void(0)"
              isExternal={false}
              tabindex={0}
              isSecondary
              onClick={() => window.print()}>
              {block?.data?.title}
            </AnchorButton>
          </div>
        </Row>
      </Container>
    )
  }
  return <></>
}

export const BuyNowButtonBlock = ({ block, index, key, colBackground }: BlockProps) => {
  const dispatch: Dispatch<any> = useDispatch()
  const { data: resp, isLoading } = useSWRRequest(block.url, async () => {
    try {
      const data = !block?.data ? await getBlockData(block?.type, block?.url, block?.background,
        block?.nid,
        block?.targetid,
        {}
      ) : block
      return data
    } catch (error) {
      console.error("[error] couldn't fetch block data ", error)
    }
  })

  if (isLoading) {
    return <SkeletonBlock />
  }

  // Assuming resp.fieldPaywallRoles is available when data is fetched
  if (resp && !isLoading) {
    dispatch(updatePaywall3Roles(Array.from(new Set(getFieldPaywallRoles(resp)))))
  }

  return (
    <Container background={colBackground ?? "white"} key={`${index}-${key}`}>
      <Row isTemplateWrapper>
        <BuyNowButton {...resp.data} />
      </Row>
    </Container>
  )
}

export const DynamicChartBlock = ({ block, colBackground }: BlockProps) => {
  return (
    <Container background={colBackground ?? "white"}>
      <Row isTemplateWrapper>
        <DynamicChart url={block?.url} />
      </Row>
    </Container>
  )
}

export const SimpleVideoBlock = ({ block, index, key, colBackground }: BlockProps) => {
  const dispatch: Dispatch<any> = useDispatch()
  const { data: resp, isLoading } = useSWRRequest(block.url, async () => {
    try {
      const data = !block?.data ? await getBlockData(block?.type, block?.url, block?.background,
        block?.nid,
        block?.targetid,
        {}
      ) : block
      return data
    } catch (error) {
      console.error("[error] couldn't fetch block data ", error)
    }
  })

  if (isLoading) {
    return <SkeletonBlock />
  }

  // Assuming resp.fieldPaywallRoles is available when data is fetched
  if (resp && !isLoading) {
    dispatch(updatePaywall3Roles(Array.from(new Set(getFieldPaywallRoles(resp)))))
  }

  return (
    <Container background={colBackground ?? "white"} key={`${index}-${key}`}>
      <Row isTemplateWrapper>
        <SimpleVideo {...resp.data} />
      </Row>
    </Container>
  )
}

export const VideoGalleryBlock = ({ block, index, key, colBackground }: BlockProps) => {
  const dispatch: Dispatch<any> = useDispatch()
  const { data: resp, isLoading } = useSWRRequest(block.url, async () => {
    try {
      const data = !block?.data ? await getBlockData(block?.type, block?.url, block?.background,
        block?.nid,
        block?.targetid,
        {}
      ) : block
      return data
    } catch (error) {
      console.error("[error] couldn't fetch block data ", error)
    }
  })

  if (isLoading) {
    return <SkeletonBlock />
  }

  // Assuming resp.fieldPaywallRoles is available when data is fetched
  if (resp && !isLoading) {
    dispatch(updatePaywall3Roles(Array.from(new Set(getFieldPaywallRoles(resp)))))
  }

  return (
    <div>
      <VideoGallery {...resp.data} />
    </div>
  )
}

export const UserQuizBlock = ({ block, index, key, colBackground }: BlockProps) => {
  const dispatch: Dispatch<any> = useDispatch()
  const { data: resp, isLoading } = useSWRRequest(block.url, async () => {
    try {
      const data = !block?.data ? await getBlockData(block?.type, block?.url, block?.background,
        block?.nid,
        block?.targetid,
        {}
      ) : block
      return data
    } catch (error) {
      console.error("[error] couldn't fetch block data ", error)
    }
  })

  if (isLoading) {
    return <SkeletonBlock />
  }

  // Assuming resp.fieldPaywallRoles is available when data is fetched
  if (resp && !isLoading) {
    dispatch(updatePaywall3Roles(Array.from(new Set(getFieldPaywallRoles(resp)))))
  }

  return (
    <div className="component-wrapper">
      <UserQuiz {...resp.data} />
    </div>
  )
}
export const IframeWrapperBlock = ({ block, colBackground }: BlockProps) => {
  const dispatch: Dispatch<any> = useDispatch()
  const { data: resp, isLoading } = useSWRRequest(block.url, async () => {
    try {
      const data = !block?.data ? await getBlockData(block?.type, block?.url, block?.background,
        block?.nid,
        block?.targetid,
        {}
      ) : block
      return data
    } catch (error) {
      console.error("[error] couldn't fetch block data ", error)
    }
  })

  if (isLoading) {
    return <SkeletonBlock />
  }

  // Assuming resp.fieldPaywallRoles is available when data is fetched
  if (resp && !isLoading) {
    dispatch(updatePaywall3Roles(Array.from(new Set(getFieldPaywallRoles(resp)))))
  }

  const { data } = resp
  return (
    <>
      {!data?.isParentPaywalled && (
        <Container background={colBackground ?? "white"}>
          <Row isTemplateWrapper>
            <IframeWrapper {...data} />
          </Row>
        </Container>
      )}
    </>
  )
}

export const PinProtectionBlock = ({ block, index, key, colBackground }: BlockProps) => {
  const dispatch: Dispatch<any> = useDispatch()
  const { data: resp, isLoading } = useSWRRequest(block.url, async () => {
    try {
      const data = !block?.data ? await getBlockData(block?.type, block?.url, block?.background,
        block?.nid,
        block?.targetid,
        {}
      ) : block
      return data
    } catch (error) {
      console.error("[error] couldn't fetch block data ", error)
    }
  })

  if (isLoading) {
    return <SkeletonBlock />
  }

  // Assuming resp.fieldPaywallRoles is available when data is fetched
  if (resp && !isLoading) {
    dispatch(updatePaywall3Roles(Array.from(new Set(getFieldPaywallRoles(resp)))))
  }

  return (
    <div>
      <PinProtection {...resp.data} />
    </div>
  )
}

export const FlexibleCarouselBlock = ({ block, index, key, colBackground }: BlockProps) => {
  const dispatch: Dispatch<any> = useDispatch()
  const { data: resp, isLoading } = useSWRRequest(block.url, async () => {
    try {
      const data = !block?.data ? await getBlockData(block?.type, block?.url, block?.background,
        block?.nid,
        block?.targetid,
        {}
      ) : block
      return data
    } catch (error) {
      console.error("[error] couldn't fetch block data ", error)
    }
  })

  if (isLoading) {
    return <SkeletonBlock />
  }

  // Assuming resp.fieldPaywallRoles is available when data is fetched
  if (resp && !isLoading) {
    dispatch(updatePaywall3Roles(Array.from(new Set(getFieldPaywallRoles(resp)))))
  }

  if (resp?.data?.carouselDisplayType === "rotating_hero_banner") {
    return (
      <Container fluid background={colBackground ?? "white"} key={`${index}-${key}`}>
        <Row isTemplateWrapper>
          <FlexibleCarousel {...resp.data} />
        </Row>
      </Container>
    )
  } else {
    return (
      <Container background={colBackground ?? "white"} key={`${index}-${key}`}>
        <Row isTemplateWrapper>
          <FlexibleCarousel {...resp.data} />
        </Row>
      </Container>
    )
  }
}

export const MagazineMasonryBlock = ({ block, index, key, colBackground }: BlockProps) => {
  const dispatch: Dispatch<any> = useDispatch()
  const { data: resp, isLoading } = useSWRRequest(block.url, async () => {
    try {
      const data = !block?.data ? await getBlockData(block?.type, block?.url, block?.background,
        block?.nid,
        block?.targetid,
        {}
      ) : block
      return data
    } catch (error) {
      console.error("[error] couldn't fetch block data ", error)
    }
  })

  if (isLoading) {
    return <SkeletonBlock />
  }

  // Assuming resp.fieldPaywallRoles is available when data is fetched
  if (resp && !isLoading) {
    dispatch(updatePaywall3Roles(Array.from(new Set(getFieldPaywallRoles(resp)))))
  }

  return (
    <Container background={colBackground} key={`${index}-${key}`}>
      <MagazineLayout {...resp.data} />
    </Container>
  )
}

export const DecisionTreeBlock = ({ block, index, key, colBackground }: BlockProps) => {
  const dispatch: Dispatch<any> = useDispatch()
  const { data: resp, isLoading } = useSWRRequest(block.url, async () => {
    try {
      const data = !block?.data ? await getBlockData(block?.type, block?.url, block?.background,
        block?.nid,
        block?.targetid,
        {}
      ) : block
      return data
    } catch (error) {
      console.error("[error] couldn't fetch block data ", error)
    }
  })

  if (isLoading) {
    return <SkeletonBlock />
  }

  // Assuming resp.fieldPaywallRoles is available when data is fetched
  if (resp && !isLoading) {
    dispatch(updatePaywall3Roles(Array.from(new Set(getFieldPaywallRoles(resp)))))
  }

  return (
    <Container background={colBackground} key={`${index}-${key}`}>
      <DecisionTree {...resp.data} />
    </Container>
  )
}

export const CustomCourseBanner = ({ block, index, key, colBackground }: BlockProps) => {
  return <CustomBanner {...block.data} key={`${index}-${key}`} />
}

export const CourseTabBlock = ({ block, index, key, colBackground }: BlockProps) => {
  return (
    <Container fluid background={colBackground} key={`${index}-${key}`}>
      <Row isTemplateWrapper>
        <CourseTabs {...block?.data} />
      </Row>
    </Container>
  )
}

export const CourseOverviewTeaserBlock = ({ block, index, key }: BlockProps) => {
  return <CourseOverviewTeaser {...block?.data} key={`${index}-${key}`} />
}

export const CourseInformationBlock = ({ block, index, key }: BlockProps) => {
  return (
    <Row className="course-overview-container">
      <CourseInformation {...block?.data} key={`${index}-${key}`} />
    </Row>
  )
}

export const CourseSpeakerBlock = ({ colBackground, block, index, key }: BlockProps) => {
  return (
    <Row className="course-speaker-container">
      <Container background={colBackground ?? "grey"} className="show-margin">
        <Row isTemplateWrapper>
          <Testimonial {...block?.data} hideQuotes={true} key={`${index}-${key}`} />
        </Row>
      </Container>
    </Row>
  )
}

export const ModuleInformationSectionBlock = ({ colBackground, block, index, key }: BlockProps) => {
  return (
    <>
      <h2 className="activity-heading bold">{block?.data?.activityHeading}</h2>
      {block?.data?.responseData &&
        block?.data?.responseData?.map((item: any, index: number) => (
          <Row
            key={`${index}-${key}`}
            className={`modules-info-sec ${index % 2 ? "" : "alternate-bg"}`}>
            <Container background={colBackground ?? "grey"} className="show-margin">
              <ModulesInfoSection {...item} course_id={block?.data?.course_id} />
            </Container>
          </Row>
        ))}
    </>
  )
}

export const ProgressChartBlock = ({ colBackground, block, index, key }: BlockProps) => {
  return (
    <Container background={colBackground ?? "grey"} className="show-margin" key={`${index}-${key}`}>
      <AcademyProgressChart {...block?.data} />
    </Container>
  )
}

export const AcademyModulesBlock = ({ block }: BlockProps) => {
  return (
    <Row isTemplateWrapper>
      <Container>
        <AcademyModules {...block?.data} />
      </Container>
    </Row>
  )
}

export const ActivityDetailsBlock = ({ block }: BlockProps) => {
  return (
    <Row isTemplateWrapper>
      <Container>
        <ActivityDetails {...block?.data} />
      </Container>
    </Row>
  )
}
