import { Button, Icon, RichText } from "@atoms"
import { useCallback, useContext, useEffect, useState } from "react"
import { IPaywallNotification } from "./_paywallNotification.interface"
import ApplicationContext from "@utils/application-context/applicationContext"
import useSandozIDAuth from "@helpers/hooks/useSandozIDAuth"
import { useDispatch, useSelector } from "react-redux"
import { updatePaywallContent } from "store/actions/CommonActionCreators"
import { Dispatch } from "redux"

const PaywallNotification = ({ paywallNotice, roles, loginHandler }: IPaywallNotification) => {
  const { applicationConfiguration } = useContext(ApplicationContext)
  const { handleLoginRedirection } = useSandozIDAuth(applicationConfiguration)
  const [allRoles, setAllRoles] = useState("")
  const common: any = useSelector((state: any) => state.common)
  const dispatch: Dispatch<any> = useDispatch()
  const updatePaywallFlag: any = useCallback(
    (value: any) => dispatch(updatePaywallContent(value)),
    [dispatch],
  )

  useEffect(() => {
    const loginLink = document.getElementById("login-paywall-notice")
    if (loginLink) {
      loginLink.onclick = () => handleClick()
    }
  }, [])

  useEffect(() => {
    if (common?.paywall3Roles || roles) {
      let allFieldPaywallRolesText =
        common?.paywall3Roles?.length > 0 ? common?.paywall3Roles?.join(", ") : ""
      allFieldPaywallRolesText =
        allFieldPaywallRolesText || roles
          ? `${allFieldPaywallRolesText}, ${roles}`
          : allFieldPaywallRolesText
      allFieldPaywallRolesText = Array.from(new Set(allFieldPaywallRolesText?.split(", ")))?.join(
        ", ",
      )
      let lastCommaIndex = allFieldPaywallRolesText.lastIndexOf(",")
      allFieldPaywallRolesText =
        allFieldPaywallRolesText.slice(0, lastCommaIndex) +
        allFieldPaywallRolesText.slice(lastCommaIndex + 1)
      if (allFieldPaywallRolesText) {
        updatePaywallFlag(true)
      }
      setAllRoles(allFieldPaywallRolesText)
    }
  }, [common?.paywall3Roles])

  const handleClick = () => {
    if (applicationConfiguration?.isSandozIDEnabled) {
      handleLoginRedirection()
    } else {
      loginHandler()
    }
  }
  const loginLink = `<span id='login-paywall-notice'><a class="anchor" role="button">${paywallNotice.loginText}</a></span>`
  const registerLink = `<a class="anchor" role="button" href=${paywallNotice.registerLink}>${paywallNotice.registerText}</a>`

  const noticeTxt = paywallNotice.description
    .replace("@[LOGIN_LINK]@", loginLink)
    .replace("@[REGISTER_LINK]@", registerLink)
    .replace("@[ROLES]@", allRoles)

  return (
    <div className="notifications">
      {allRoles && (
        <div className="notifications-container">
          <div className="notifications-desc">
            <span className="notifications-container-icon">
              <Icon iconName="NotifyIcon" />
            </span>
            <div className="notifications-container-richtext">
              <RichText content={noticeTxt} />
            </div>
          </div>
          <Button tabindex={0} isSecondary={false} onClick={handleClick}>
            {paywallNotice.ctaLabel}
          </Button>
        </div>
      )}
    </div>
  )
}

export default PaywallNotification
