import {
  LAYOUT_REGIONS,
  PAGE_NID_FILTER,
  PIN_PROTECTION,
  SECTION_LAYOUT,
  STICKY_STYLE,
} from "@helpers/constants/generic"
import NUMBERS from "@helpers/constants/numbers"
import { ISeoProps , IOg } from "../../components/atoms/seo/_seo.interface"
import { getApi } from "../../utils/baseApi"
import { consoleError } from "../../utils/error"
import getBlockData from "./_flexibleApi.functions"

const _traverseSeo = async (seo: any) => {
  let canonical = ""
  let title = ""
  let description = ""
  let keywords = ""
  let googleSiteVerification = ""
  let og : IOg = {}
  seo?.map((meta: any) => {
    if (meta?.tag === "link") {
      if (meta?.attributes?.rel === "canonical") {
        canonical = meta?.attributes?.href
      }
    }
    if (meta?.tag === "meta") {
      if (meta?.attributes?.name === "title") {
        title = meta?.attributes?.content
      }
    }
    if (meta?.tag === "meta") {
      if (meta?.attributes?.name === "description") {
        description = meta?.attributes?.content
      }
    }
    if (meta?.tag === "meta") {
      if (meta?.attributes?.name === "google-site-verification") {
        googleSiteVerification = meta?.attributes?.content
      }
    }
    if (meta?.tag === "meta") {
      if (meta?.attributes?.name === "keywords") {
        keywords = meta?.attributes?.content
      }
    }
    if (meta?.attributes?.property?.startsWith("og:")) {
      const property = meta?.attributes?.property.substring(3)
      og[property] = meta?.attributes?.content
    }
  })
  return {
    canonical,
    title,
    description,
    googleSiteVerification,
    keywords,
    og,
  }
}

const getRegionNumber = (region: string) => {
  switch (region) {
    case LAYOUT_REGIONS.FIRST:
      return NUMBERS.ZERO
    case LAYOUT_REGIONS.SECOND:
      return NUMBERS.ONE
    case LAYOUT_REGIONS.THIRD:
      return NUMBERS.TWO
    default:
      return NUMBERS.ZERO
  }
}

/**
 * Used for formatting page config object for Microsite
 * @param config
 * @returns
 */
export const getMicrositePageConfigData = (config: any) => {
  let configObj = {}
  if (Array.isArray(config)) {
    config?.forEach((data: any) => {
      const temp = typeof data === "string" ? JSON.parse(data) : data
      configObj = { ...configObj, ...temp }
    })
  }
  return configObj
}

export const getRenderHcpModalPopUp = (pageData: any) => {
  if (!pageData) {
    return {}
  }
  return {
    header: {
      titleText: pageData?.popup_title || "",
    },
    content: {
      content: pageData?.popup_desc?.value || "",
      isRichText: true,
    },
    footer: {
      closeBtn: {
        tabindex: NUMBERS.TWO,
        children: pageData?.popup_sec_cta_link?.title || "",
        isSecondary: true,
        href: pageData?.popup_sec_cta_link?.uri ?? "",
        isExternal: pageData?.popup_sec_cta_link?.options?.attributes?.target ?? false,
        target: pageData?.popup_sec_cta_link?.options?.attributes?.target ?? "",
      },
      actionBtn: {
        tabindex: NUMBERS.ONE,
        children: pageData?.popup_pri_cta_label || "",
      },
    },
    enabled: pageData?.modal_popup_checkbox === true ? 1 : 0,
    pageLevel: true,
  }
}

export const checkKeyExists = (arr: any, value: any) => {
  let found = false
  for (let i = 0; i < arr?.length; i++) {
    if (arr[i]?.layout_id === value) {
      if (found) {
        return true
      }
      found = true
    }
  }
  return false
}

export const getFlexiblePageBlocks = async (
  blockEndPointAPI: string,
  preFetchedData?: any,
  token?: string,
  serverData?: any,
) => {
  if (!blockEndPointAPI) {
    consoleError(`URL parameter is undefined in ${getFlexiblePageBlocks.name}.`)
    return
  }

  const pageData = preFetchedData ?? (await getApi(blockEndPointAPI, false, serverData))
  let isPaywallEnabled: boolean = false
  let isPaywallNoticeEnabled = false
  const currentUID = pageData?.data?.current_uid
  const is404 = pageData?.is404 ?? false
  if (pageData) {
    if (pageData?.paywall) {
      isPaywallEnabled = true
    }
  }

  const seoData: ISeoProps = await _traverseSeo(pageData?.data?.attributes?.metatag)

  const nid = pageData?.data?.attributes?.drupal_internal__nid ?? null
  const targetid =
    pageData?.data?.relationships?.field_media_specialty?.data?.[0]?.meta
      ?.drupal_internal__target_id ?? null
  const pageThemeId =
    pageData?.data?.relationships?.field_sub_theme?.data?.meta?.drupal_internal__target_id ?? null
  const pageThemeName = pageData?.data?.relationships?.field_sub_theme?.data?.meta?.term_name || ""
  let pageTheme = ""
  if (pageThemeName || pageThemeId) {
    pageTheme = pageThemeName
      ? pageThemeName
      : pageData?.included?.filter(
          (item: any) => item?.attributes?.drupal_internal__tid === pageThemeId,
        )?.[0]?.attributes?.name || ""
  }
  const layoutBuilder = pageData?.data?.attributes?.layout_builder__layout
  const mlrID = pageData?.data?.attributes?.field_p3_number || null
  const pathAlias = pageData?.data?.attributes?.path?.alias ?? null
  const isiBannerDetails = pageData?.data?.attributes?.isi_banner_details ?? null
  const bgImage = pageData?.data?.relationships?.full_page_bg_img?.data?.meta?.absolute_uri ?? ""
  const bgMobImage =
    pageData?.data?.relationships?.full_page_bg_img_mobile?.data?.meta?.absolute_uri ?? ""
  const bgImageOpacity = pageData?.data?.attributes?.full_page_bg_img_opacity / 100 ?? 1
  const bgMobImageOpacity = pageData?.data?.attributes?.full_page_bg_img_opacity_mobile / 100 ?? 1


  const allComponents: unknown[] = []
  let allPaywallRoles: unknown[] = []

  const isMicrositeEnabled = pageData?.data?.attributes?.is_microsite === "1"
  const isPatientEnabled = pageData?.data?.attributes?.field_pin_protection_page ?? false
  const isHCPEnabled = pageData?.data?.attributes?.field_pin_protection_for_hcp ?? false

  const pinProtectionFallbackPageUrl = pageData?.pin_protection_page_url ?? "/"
  const tabHeadItems: any = []
  const tabContents: any = []
  let counter = 0

  const isPinProtectionEnabled = isPatientEnabled
    ? PIN_PROTECTION.PATIENT
    : isHCPEnabled
    ? PIN_PROTECTION.HCP
    : false

  let micrositeConfig = null

  if (isMicrositeEnabled) {
    micrositeConfig = {
      isMicrositeEnabled,
      menuStructure: pageData?.data?.attributes?.microsite_menu_structure_level || "",
      pageMenuLvl: pageData?.data?.attributes?.microsite_menu_level || "0",
      pageConfig: pageData?.data?.attributes?.microsite_component
        ? getMicrositePageConfigData(pageData?.data?.attributes?.microsite_component)
        : null,
      disabledInhConfig: {
        banner: pageData?.data?.attributes?.field_disable_miscrosite_banner ?? false,
        footer: pageData?.data?.attributes?.field_disable_microsite_footer ?? false,
        eyebrow_menu: pageData?.data?.attributes?.field_disable_ms_eyebrow_menu ?? false,
        header: pageData?.data?.attributes?.field_disable_microsite_header ?? false,
      },
    }
  }

  let tabLabel
  let tabToggle
  let checkTabExits = checkKeyExists(layoutBuilder, SECTION_LAYOUT.TABS)

  layoutBuilder?.forEach((layout: any) => {
    const isProtectedContent = !!(layout?.error_code && layout?.error_code === 405)
    if (isProtectedContent) {
      isPaywallNoticeEnabled = true
      allPaywallRoles = allPaywallRoles.concat(layout?.unauth_roles)
    }
    const column = layout?.layout_id
    const secBgImage =
      layout?.third_party_settings?.layout_builder_bg_img_wrapper?.bg_img_details?.absolute_uri ??
      ""
    const secBgMobImage =
      layout?.third_party_settings?.layout_builder_bg_img_wrapper?.bg_img_mobile_details
        ?.absolute_uri ?? ""
    const secBgImageOpacity =
      layout?.third_party_settings?.layout_builder_bg_img_wrapper?.bg_img_opacity / 100 ?? 1
    const secBgMobImageOpacity =
      layout?.third_party_settings?.layout_builder_bg_img_wrapper?.bg_img_opacity_mob / 100 ?? 1
    const secId = layout?.third_party_settings?.layout_builder_section_id_wrapper?.section_id ?? ""

    const columnWidths = layout?.layout_settings?.column_widths
    const background = Array.isArray(layout?.layout_settings?.layout_builder_styles_style)
      ? layout?.layout_settings?.layout_builder_styles_style?.filter(
          (sty: any) => !STICKY_STYLE?.includes(sty) && sty !== "",
        )?.[0]
      : null
    const stickyStyle =
      Array.isArray(layout?.layout_settings?.layout_builder_styles_style) &&
      layout?.layout_settings?.layout_builder_styles_style

    tabLabel = layout?.layout_settings?.label && layout.layout_settings.label
    tabToggle =
      layout?.layout_settings?.toggle_switch_value && layout?.layout_settings?.toggle_switch_value
    const components = layout?.components
    const sectionComponents: unknown[] = []

    components?.forEach((component: any) => {
      const type =
        component?.configuration?.type === "webform_block" &&
        (component?.configuration?.webform_id === "sandoz_contact_us" ||
          component?.configuration?.webform_id === "newsletter_signup")
          ? component?.configuration?.webform_id
          : component?.configuration?.type
      // Add condition for all blocks to be skipped
      if (["page_footer", "isi_legal_disclaimer_banner", "eyebrow_menu", "header"].includes(type)) {
        return
      }
      const uuid = component?.configuration?.uuid
      const configUrl = component?.configuration?.url
      const url = configUrl
        ? `${configUrl}${configUrl.includes("?") ? "&" : "?"}${PAGE_NID_FILTER}${nid}`
        : ""
      const label = component?.configuration?.label
      const region = component?.region
      const weight = component?.weight
      const data = component?.configuration?.data

      /**
       * In case, block added but removed again from CMS. content reference still present on layout JSON
       * In such cases, add block only url being present on payload
       */

      if (url) {
        sectionComponents.push({
          column: column || null,
          background: background || null,
          type: type || null,
          uuid: uuid || null,
          url: url || null,
          label: label || null,
          region: getRegionNumber(region) || NUMBERS.ZERO,
          weight: weight || NUMBERS.ZERO,
          stickyStyle,
          columnWidths: columnWidths || null,
          data: data || null,
          bgImage: secBgImage,
          bgMobImage: secBgMobImage,
          bgImageOpacity: secBgImageOpacity,
          bgMobImageOpacity: secBgMobImageOpacity,
          sectionId: secId || null,
          nid: nid,
          targetid: targetid,
        })
      }
    })
    //condition for tab layout
    if (column === SECTION_LAYOUT.TABS) {
      tabHeadItems.push({
        itemID: counter,
        children: layout.layout_settings.label || "",
        referrerId: layout.layout_settings.initially_active_item,
      })
      tabContents.push({
        itemID: counter,
        blocks: [sectionComponents],
      })
      counter++
      if (!checkTabExits && tabToggle === 1 && !!layout?.layout_settings?.label) {
        const columnSectionTab = {
          column: SECTION_LAYOUT.ONE_COL,
          background: null,
          type: SECTION_LAYOUT.TABS || null,
          uuid: null,
          url: null,
          label: tabLabel,
          region: NUMBERS.ZERO,
          weight: NUMBERS.ZERO,
          stickyStyle: null,
          columnWidths: null,
          data: { tabHeadItems, tabContents },
          bgImage: null,
          bgMobImage: null,
          bgImageOpacity: null,
          bgMobImageOpacity: null,
          sectionId: null,
        }
        allComponents.push([columnSectionTab])
      }
    } else {
      allComponents.push(
        sectionComponents?.sort(
          (comp1: any, comp2: any) => (comp1?.weight || 0) - (comp2?.weight || 0),
        ),
      )
    }
  })

  if (
    counter > 0 &&
    checkKeyExists(layoutBuilder, SECTION_LAYOUT.TABS) &&
    tabContents.length > 0 &&
    (tabToggle === 1 ||  !tabToggle )
  ) {
    const columnSectionTab = {
      column: SECTION_LAYOUT.ONE_COL,
      background: null,
      type: SECTION_LAYOUT.TABS || null,
      uuid: null,
      url: null,
      label: tabLabel,
      region: NUMBERS.ZERO,
      weight: NUMBERS.ZERO,
      stickyStyle: null,
      columnWidths: null,
      data: { tabHeadItems, tabContents },
      bgImage: null,
      bgMobImage: null,
      bgImageOpacity: null,
      bgMobImageOpacity: null,
      sectionId: null,
    }
  
    const index = allComponents?.findIndex((innerArray:any) =>
      innerArray?.some((item:any) => item?.type === "sandoz_academy_hero_banner_block")
    );
  
    if (index !== -1) {
      allComponents?.splice(index + 1, 0,[columnSectionTab])
    }
    
  }

  else if(pageData?.data?.type==="node--sandoz_academy_modules"){
    const sectionData = {
      column: SECTION_LAYOUT.ONE_COL,
      background: null,
      type: "activity_details_block",
      uuid: null,
      url: null,
      label: tabLabel,
      region: NUMBERS.ZERO,
      weight: NUMBERS.ZERO,
      stickyStyle: null,
      columnWidths: null,
      data: pageData,
      bgImage: null,
      bgMobImage: null,
      bgImageOpacity: null,
      bgMobImageOpacity: null,
      sectionId: null,
    }
    allComponents.push([sectionData])
  }

  const uniquePaywallRoles = allPaywallRoles.filter((value, index, self) => {
    return self.indexOf(value) == index
  })
  const paywallRolesStr = uniquePaywallRoles.join(", ")

  const renderHcpModalPopUp = getRenderHcpModalPopUp(pageData?.data?.attributes) as any

  const headerConfiguration = {
    isHeaderDisabled: pageData?.data?.attributes?.field_disable_header ?? false,
    isLogoDisabled: pageData?.data?.attributes?.field_disable_logo ?? false,
    isSloganDisabled: pageData?.data?.attributes?.field_disable_slogan ?? false,
    isLoginRegisterDisabled: pageData?.data?.attributes?.field_disable_login_register ?? false,
    isMegaMenuDisabled: pageData?.data?.attributes?.field_disable_megamenu ?? false,
    isSearchDisabled: pageData?.data?.attributes?.field_disable_search ?? false,
    isLangSwitcherDisabled: pageData?.data?.attributes?.field_disable_language_switcher ?? false,
    isCartDisabled: pageData?.data?.attributes?.field_disable_cart ?? false,
  }

  const referenceTextData = {
    referenceTextFlag: pageData?.data?.attributes?.reference_text_flag ?? false,
    referenceTextTitle: pageData?.data?.attributes?.reference_text_title ?? "",
    referenceTextDesc: pageData?.data?.attributes?.reference_text_description?.value ?? "",
  }

  return {
    pageBlocks: allComponents,
    seoData,
    nid,
    is404,
    targetid,
    paywall: isPaywallEnabled,
    paywallNotice: isPaywallNoticeEnabled,
    paywallRoles: paywallRolesStr ?? "",
    paywallText: pageData?.cause ?? "",
    uid: currentUID ?? "NOT AVAILABLE",
    pathAlias,
    mlrID,
    pageTheme,
    headerConfiguration,
    renderHcpModalPopUp,
    isDeepLink: !!token,
    isiBannerDetails,
    bgImage,
    bgMobImage,
    bgImageOpacity,
    bgMobImageOpacity,
    micrositeConfig,
    isPinProtectionEnabled,
    pinProtectionFallbackPageUrl: pinProtectionFallbackPageUrl ?? null,
    referenceTextData: referenceTextData ?? null
  }
}

export const getAllFleixbleBlocksData = async (
  pageBlocks: any[] = [],
  nid?: number,
  targetid?: number,
  paywallText?: string,
  serverData?: any,
) => {
  let blockData: any = []

  blockData = await Promise.all(
    pageBlocks.map(
      async (blockList: any, idx: number) =>
        await Promise.all(
          blockList?.map(async (block: any, index: number) => {
            const data = await getBlockData(
              block?.type,
              block?.url,
              block?.background,
              nid || NUMBERS.ZERO,
              targetid || NUMBERS.ZERO,
              block?.data || {},
              serverData,
            )
            const width = block?.columnWidths?.split("-")?.[block.region] ?? NUMBERS.HUNDRED
            return data
              ? {
                  ...data,
                  url: block?.url,
                  paywallCause: paywallText ?? "",
                  column: block?.column,
                  region: block?.region,
                  weight: block?.weight,
                  stickyStyle: block?.stickyStyle,
                  width,
                  bgImage: block?.bgImage,
                  bgMobImage: block?.bgMobImage,
                  bgImageOpacity: block?.bgImageOpacity,
                  bgMobImageOpacity: block?.bgMobImageOpacity,
                  sectionId: block?.sectionId,
                  nid,
                  targetid,
                }
              : null
          }),
        ),
    ),
  )
  return blockData || null
}

export const getAllPagePaths = async () => {
  const path: any = []
  let url = "/jsonapi/path_alias/path_alias"

  while (url) {
    const rawData = await getApi(url)
    const pagePaths = rawData?.data
    if (Array.isArray(pagePaths)) {
      pagePaths.forEach((pagePath) => {
        path.push(pagePath?.attributes?.alias)
      })
    }
    url = rawData?.links?.next?.href
  }

  return path
}