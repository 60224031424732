/* eslint-disable react/jsx-no-useless-fragment */
import Loader from "@atoms/loader"
import getAccordionAdvancedData from "@helpers/dataFunctions/getAccordionAdvancedData"
import { memo, useCallback, useEffect, useState } from "react"
import { v4 } from "uuid"
import AccordionAdvancedContent from "@molecules/accordionAdvancedContent"
import { IAccordionAdvancedProps, IAccordionDataProps } from "./_accordionAdvanced.interface"
import { useDispatch, useSelector } from "react-redux"
import { updatePaywall3Roles, updatePaywallContent } from "store/actions/CommonActionCreators"
import { Dispatch } from "redux"
import { useRouter } from "next/router"

const AccordionAdvanced = ({ id, url }: IAccordionAdvancedProps) => {
  const accordId = v4()
  const [accordionBehavior, setAccordionBehavior] = useState<any>(null)
  const [cardInitValue, setCardInitValue] = useState<any>(null)
  const [flexData, setFlexData] = useState<any>(null)
  const [isLoading, setIsLoading] = useState<any>(true)
  const user: any = useSelector((state: any) => state?.user)
  const router = useRouter()
  const dispatch: Dispatch<any> = useDispatch()
  const updateAccordionPaywall3Roles: any = useCallback(
    (value: any) => dispatch(updatePaywall3Roles(value)),
    [dispatch],
  )
  const updateAccordionPaywallContent: any = useCallback(
    (value: any) => dispatch(updatePaywallContent(value)),
    [dispatch],
  )

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      try {
        const response = await getAccordionAdvancedData(url)
        setFlexData(response)
        setIsLoading(false)
      } catch (error) {
        setIsLoading(false)
        console.error("Error in fetching video gallery data")
      }
    }
    fetchData()
  }, [])

  useEffect(() => {
    if (router?.query?.acc && router?.query?.acc == flexData?.referrerId && !isLoading) {
      setAccordionBehavior("Expand selected")
      setCardInitValue("1")
    }
  }, [router.query, isLoading])
  
  useEffect(() => {
    if (!isLoading && flexData?.fieldPaywallRoles?.length && !user?.isLoggedIn) {
        updateAccordionPaywall3Roles(Array.from(new Set(flexData?.fieldPaywallRoles)))
        updateAccordionPaywallContent(true)
      }
  }, [isLoading, flexData, user])

  return (
    <>
      {isLoading ? (
        <div className="load-container">
          <Loader display={true} />
        </div>
      ) : (
        <>
          {!flexData?.isParentPaywalled && (
            <div id={flexData?.referrerId} className="accordion-advanced">
              <div className="accordion" id={id || `accord-${accordId}`}>
                {flexData?.accordionArray?.map((item: IAccordionDataProps, index: number) => (
                  <AccordionAdvancedContent
                    key={index}
                    accordId={accordId}
                    accordionBehavior={(accordionBehavior && index === 0) ? accordionBehavior : flexData?.accordionBehavior}
                    accordionExperience={flexData?.accordionExperience}
                    cardInitState={(cardInitValue && index === 0) ? cardInitValue : item.cardInitState}
                    index={index}
                    cardUrl={item?.cardUrl}
                    id={id || `accord-${accordId}`}
                    cardId={item?.cardId}
                  />
                ))}
              </div>
            </div>
          )}
        </>
      )}
    </>
  )
}
export default memo(AccordionAdvanced)
