import { LAYOUT_REGIONS, STICKY_STYLE } from "@helpers/constants/generic"
import NUMBERS from "@helpers/constants/numbers"
import { uniqueArray } from "@utils/helper"
import { getBlockData } from "."
import { ITabItemProps } from "../../components/atoms/tab-item/_tab-item.interface"
import { getAllFleixbleBlocksData } from "../flexibleApi"
import { getAllRoles, isPaywallProtected } from "@utils/paywallUtilities"
import getNestedObject from "@utils/nestedObjects"

export const getRegionNumber = (region: string) => {
  switch (region) {
    case LAYOUT_REGIONS.FIRST:
      return NUMBERS.ZERO
    case LAYOUT_REGIONS.SECOND:
      return NUMBERS.ONE
    default:
      return NUMBERS.ZERO
  }
}

export const fetchTabContent = async (layoutBuilder: any, serverData?: any) => {
  let allComponents: any = []

  layoutBuilder?.forEach((layout: any) => {
    const column = layout?.layout_id
    const columnWidths = layout?.layout_settings?.column_widths
    const secBgImage =
      layout?.third_party_settings?.layout_builder_bg_img_wrapper?.bg_img_details?.absolute_uri ??
      ""
    const secBgMobImage =
      layout?.third_party_settings?.layout_builder_bg_img_wrapper?.bg_img_mobile_details
        ?.absolute_uri ?? ""
    const secBgImageOpacity =
      layout?.third_party_settings?.layout_builder_bg_img_wrapper?.bg_img_opacity / 100 ?? 1
    const secBgMobImageOpacity =
      layout?.third_party_settings?.layout_builder_bg_img_wrapper?.bg_img_opacity_mob / 100 ?? 1
    const secId = layout?.third_party_settings?.layout_builder_section_id_wrapper?.section_id ?? ""

    const background = Array.isArray(layout?.layout_settings?.layout_builder_styles_style)
      ? layout?.layout_settings?.layout_builder_styles_style?.filter(
          (sty: any) => !STICKY_STYLE?.includes(sty) && sty !== "",
        )?.[0]
      : null
    const stickyStyle =
      Array.isArray(layout?.layout_settings?.layout_builder_styles_style) &&
      layout?.layout_settings?.layout_builder_styles_style
    const components = layout?.components
    const sectionComponents: unknown[] = []

    components?.forEach((component: any) => {
      const type = component?.configuration?.type
      const uuid = component?.configuration?.uuid
      const url = component?.configuration?.url
      const label = component?.configuration?.label
      const region = component?.region
      const weight = component?.weight

      sectionComponents.push({
        column: column ?? null,
        background: background ?? null,
        type: type ?? null,
        uuid: uuid ?? null,
        url: url ?? null,
        label: label ?? null,
        region: getRegionNumber(region) ?? null,
        weight: weight ?? NUMBERS.ZERO,
        stickyStyle,
        columnWidths,
        bgImage: secBgImage,
        bgMobImage: secBgMobImage,
        bgImageOpacity: secBgImageOpacity,
        bgMobImageOpacity: secBgMobImageOpacity,
        sectionId: secId || null,
      })
    })
    allComponents.push(
      sectionComponents?.sort(
        (comp1: any, comp2: any) => (comp1?.weight || 0) - (comp2?.weight || 0),
      ),
    )
  })

  allComponents = uniqueArray(allComponents)

  return {
    blocks: await getAllFleixbleBlocksData(allComponents, undefined, undefined, undefined, serverData),
  }
}

const sortItems = (data: any) => data.sort((a: any, b: any) => a.itemID - b.itemID)

const getFlexibleTabsData = async (url: string, ...args: any) => {
  const serverData = args?.[3] // accepting serverData only
  let tabHeadItems: ITabItemProps[] = []
  const tabContents: any = []
  const flexibleTabs = await getBlockData(url, false, serverData)
  const isParentPaywalled = isPaywallProtected(
    getNestedObject(flexibleTabs, "data.attributes.enable_paywall_block_level"),
  )?.isPaywallProtectedFlag
  const fieldPaywallRoles = getAllRoles(flexibleTabs)
  for (const [key, item] of (flexibleTabs?.included || []).entries()) {
    const isChildPaywalled = isPaywallProtected(
      getNestedObject(item, "attributes.enable_paywall_block_level"),
    )?.isPaywallProtectedFlag
    const tabContent = await fetchTabContent(item?.attributes?.layout_builder__layout, serverData)
    tabHeadItems.push({
      itemID: isChildPaywalled ? null : key.toString(),
      children: isChildPaywalled ? null : item?.attributes?.field_accordion_title?.value ?? "",
      referrerId: isChildPaywalled ? null : item?.attributes?.field_referrer_id ?? "",
    })
    tabContents.push({ itemID: isChildPaywalled ? null : key.toString(), blocks: isChildPaywalled ? null : tabContent.blocks })
  }
  tabHeadItems = tabHeadItems.filter(item => item.itemID !== null && item.children !== null)

  return {
    tabHeaderItems: sortItems(tabHeadItems) ?? null,
    tabBodyContents: sortItems(tabContents) ?? null,
    activeTab: !tabHeadItems?.[0]?.children ?? null,
    isParentPaywalled,
    fieldPaywallRoles
  }
}

export default getFlexibleTabsData
